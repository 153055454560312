import React from 'react'

function Footer() {

    const d = new Date();
    let year = d.getFullYear();

    return (
        <>
            {/* <!--================ start footer Area  =================--> */}
            <footer className="footer-area section_gap">
                <div className="container-fluid">
                    <div className="row d-flex justify-content-between">

                        <div className="col-lg-3 col-md-4 col-12 footer_coloumn">
                            <h3 className="footer_section_title">Uselink</h3>
                            <div className="d-flex links_coloumn">
                                <ul className="footer_list mr-5">
                                    <li className="footer_list_item"><a href="#">Legal & Privacy</a></li>
                                    <li className="footer_list_item"><a href="#">Contact</a></li>
                                    <li className="footer_list_item"><a href="#">Gift Card</a></li>
                                    <li className="footer_list_item"><a href="#">Customer Service</a></li>
                                    <li className="footer_list_item"><a href="#">My Account</a></li>
                                    <li className="footer_list_item"><a href="#">Find a Store</a></li>
                                </ul>
                                <ul className="footer_list">
                                    <li className="footer_list_item"><a href="#">My Profile</a></li>
                                    <li className="footer_list_item"><a href="#">My Order</a></li>
                                    <li className="footer_list_item"><a href="#">History</a></li>
                                    <li className="footer_list_item"><a href="#">My Wish</a></li>
                                    <li className="footer_list_item"><a href="#">List Order</a></li>
                                    <li className="footer_list_item"><a href="#">Tracking</a></li>
                                    <li className="footer_list_item"><a href="#">Shopping Cart</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-12 footer_coloumn">
                            <div className="text-center">
                                <h3 className="subtop">Stay caffeinated</h3>
                                <h4>Get the latest information and special offers</h4>
                                <div className="input-group">
                                    <input type="email" value="" placeholder="Your email" name="contact[email]"
                                        id="NewsletterEmail-ca62ca6c-19f9-417f-ba72-c7e039eefc3a" className="form-control" />
                                    <div className="input-group-append">
                                        <button type="submit" className="btn-theme px-3" name="commit">
                                            Subscribe
                                            <div className="icon"> <i className="fa-solid fa-arrow-right ml-1"></i></div>
                                        </button>
                                    </div>
                                </div>

                                <div className="social_icons_area d-flex justify-content-center">
                                    <div className="social_icons mr-4"><a href="#"><i className="fa-brands fa-facebook-f"></i></a></div>
                                    <div className="social_icons mr-4"><a href="#"><i className="fa-brands fa-twitter"></i></a></div>
                                    <div className="social_icons mr-4"><a href="#"><i className="fa-brands fa-pinterest-p"></i></a></div>
                                    <div className="social_icons"><a href="#"><i className="fa-brands fa-instagram"></i></a></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-12 footer_coloumn">
                            <div className="text-right touch_coloumn">
                                <h3 className="footer_section_title">Get in touch</h3>
                                <div className="pb-3">
                                    <h5 className="phone">8 (844) 880 - 33388</h5>
                                    <p>customer_support@example.com</p>
                                </div>
                                <ul className="time pl-0">
                                    <li>Mon - Sat: <span>7.00am - 8.00pm</span></li>
                                    <li>Sun: <span>9.00am - 10.00pm</span></li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    <div className="row footer-bottom">
                        <p className="col-lg-12 footer-text text-center">
                            {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                            Copyright &copy; {year} All rights reserved
                        </p>
                    </div>
                </div>
            </footer>
            {/* <!--================ End footer Area  =================--> */}
        </>
    )
}

export default Footer
