import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Subscription from "../components/Subscription";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { Alert } from "react-bootstrap";
import { getAddreses, getCarts, getPaymentMethods, placeOrder } from "../ContextAPI/APIs/api";
import { Alert } from "../ContextAPI/Components/notify";
import Addaddress from "./Addaddress";
import { File_URL } from "../config";
import { handleImageError } from "../helpers/helper";

function Checkout() {
  
  const navigate = useNavigate()
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [carts, setCarts] = useState([]);
  const [totalOrderAmount, setTotalOrderAmount] = useState(0);
  const [addreses, setAddreses] = useState([])
  const [show, setShow] = useState(false);
  const [address, setaddress] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    payment_type_id: "",
    address: "",
    city: "",
    postal_code: "",
    street_no: "",

  })

  //  get addreses 

  const getAddressDetail = async () => {
    try {
      const response = await getAddreses()
      if (response.success) setAddreses(response.addreses)
    } catch (error) {
      console.log(error)
      Alert(error.message)
    }
  }

  const getPaymentTypes = async () => {
    try {
      const response = await getPaymentMethods();
      if (response.success) setPaymentMethods(response.paymentMethods);
    } catch (error) {
      console.log(error);
      Alert(error.message);
    }
  };


  //   get carts 


  const getUserCartsData = async () => {
    try {
      console.log("arha h ");
      const resposne = await getCarts();
      if (resposne.success) {
        setCarts(resposne.carts);
        setTotalOrderAmount(resposne.totalOrderAmount);
      }
    } catch (error) {
      console.log(error);
      Alert(error.message);
    }
  };

  const setFormVales = async (value) => {
    // setaddress(value);

    const obj = JSON.parse(value)

    setFormData({
      ...formData, address: obj.addressLine1,
      city: obj.city,
      postal_code: obj.zipCode,
      street_no: obj.streetNo
    })

    // console.log(JSON.stringify(obj));
    // console.log(JSON.parse(value));
  }


  useEffect(() => {
    getUserCartsData();
    getPaymentTypes();
    getAddressDetail();

  }, []);



  //   place order api call 
  const handleOrderPlace = async (e) => {
    e.preventDefault()
    try {
      const response = await placeOrder(formData)

      if (response.success)
        navigate('/thankyou')
      return Alert(response.message)
    } catch (error) {
      console.log(error);
      Alert(error.message)
    }
  }




  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  return (
    <>
      <Navbar />

      {/* <!--================Home Banner Area =================--> */}
      <Banner
        BannerHeading="Product Checkout"
        PageRoute="/checkout"
        PageLink="Checkout"
      />
      {/* <!--================End Home Banner Area =================--> */}

      {/* <!--================Checkout Area =================--> */}

      <Modal show={show} onHide={handleClose} centered>

        <Modal.Body>
          <Addaddress />
        </Modal.Body>

      </Modal>


      <section className="checkout_area section_gap">
        <div className="container">

          <div className="billing_details">

            <form className="contact_form" onSubmit={(e) => handleOrderPlace(e)} action="/confirmation.html">
              <div className="row">
                <div className="col-md-6">
                  <h3>Billing Details</h3>
                  <div className="row">
                    <div className="col-md-6 form-group p_star">
                      <input
                        type="text"
                        className="form-control"
                        required
                        id="first"
                        placeholder="First name *"
                        value={formData.first_name}
                        onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                      />
                      {/* <!-- <span className="placeholder" data-placeholder="First name"></span> --> */}
                    </div>

                    <div className="col-md-6 form-group p_star">
                      <input
                        type="text"
                        className="form-control"
                        required
                        id="last"
                        placeholder="Last name *"
                        value={formData.last_name}
                        onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                      />
                      {/* <!-- <span className="placeholder" data-placeholder="Last name"></span> --> */}
                    </div>
                    <div className="col-md-6 form-group p_star">
                      <input
                        type="tel"
                        className="form-control"
                        required
                        id="number"
                        placeholder="Phone number *"
                        name="number"
                        value={formData.phone}
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                      />
                      {/* <!-- <span className="placeholder" data-placeholder="Phone number"></span> --> */}
                    </div>
                    <div className="col-md-6 form-group p_star">
                      <input
                        type="email"
                        className="form-control"
                        required
                        id="email"
                        placeholder="Email Address *"
                        name="compemailany"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      />

                      {/* <!-- <span className="placeholder" data-placeholder="Email Address"></span> --> */}
                    </div>
                    <div className="col-md-12 form-group addreses d-flex">
                      <select className="form-select" onChange={(e) => setFormVales(e.target.value)}  >
                        <option selected value={`{"streetNo": "", "addressLine1": "", "city": "", "zipCode": ""}`}>Select address</option>
                        {addreses.map((option, i) => {
                          return <>
                            <option key={i} value={`{"streetNo": "${option.street_number}", "addressLine1": "${option.address_line1}", "city": "${option.city}", "zipCode": "${option.postal_code}"}`}>{option.street_number} {option.address_line1} {option.city}{option.postal_code}</option>
                          </>
                        })}
                      </select>
                      <button className="add_address_btn ml-2" onClick={handleShow}> + </button>

                    </div>

                    <div className="col-md-12 form-group">
                      <input
                        type="number"
                        className="form-control"
                        id="zip"
                        name="zip"
                        placeholder="Postcode / ZIP"
                        value={formData.street_no}
                        onChange={(e) => setFormData({ ...formData, street_no: e.target.value })}
                      />
                    </div>
                    <div className="col-md-12 form-group p_star">
                      <input
                        type="text"
                        className="form-control"
                        required
                        id="add1"
                        placeholder="Address line 01 *"
                        name="add1"
                        value={formData.address}
                        onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                      />
                      {/* <!-- <span className="placeholder" data-placeholder="Address line 01"></span> --> */}
                    </div>
                    {/* <div className="col-md-12 form-group p_star"> */}
                    {/* <input
                      type="text"
                      className="form-control"
                      id="add2"
                      placeholder="Address line 02"
                      name="add2"
                    /> */}
                    {/* <!-- <span className="placeholder" data-placeholder="Address line 02"></span> --> */}
                    {/* </div> */}
                    <div className="col-md-12 form-group p_star">
                      <input
                        type="text"
                        className="form-control"
                        required
                        id="city"
                        placeholder="Town/City *"
                        name="city"
                        value={formData.city}
                        onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                      />
                      {/* <!-- <span className="placeholder" data-placeholder="Town/City"></span> --> */}
                    </div>
                    <div className="col-md-12 form-group">
                      <input
                        type="number"
                        className="form-control"
                        id="zip"
                        name="zip"
                        placeholder="Postcode / ZIP"
                        value={formData.postal_code}
                        onChange={(e) => setFormData({ ...formData, postal_code: e.target.value })}
                      />
                    </div>

                    <div className="col-md-12 form-group p_star">
                      <select className="form-select" value={formData.payment_method_id}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            payment_type_id: e.target.value,
                          })
                        } >
                        <option selected value="" disabled>Select payment method</option>
                        {paymentMethods.map((p) => (
                          <option key={p.id} value={p.id}>
                            {p.value}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-12 form-group">
                      <textarea
                        className="form-control"
                        name="message"
                        id="message"
                        rows="1"
                        placeholder="Order Notes"
                      >
                      </textarea>
                    </div>

                  </div>
                </div>

                <div className="col-md-6">
                  <div className="order_box">
                    <h2>Your Order</h2>
                    <div className="list">
                      <div className="d-flex jc-between">
                        <p>Product</p>
                        <p>Total</p>
                      </div>

                      {carts.map((c, i) => (
                        <div className="row mb-2" key={i}>
                          {/* <div className='d-flex flex-wrap jc-between'> */}

                          <>
                            <div className={`${isMobile ? "col-12" : "col-lg-8 col-md-7 col-sm-8 col-8"}`} id="checkoutProducts" >

                              <img className="img-fluid me-2" src={`${File_URL}/${c.image_url}`} alt="image" style={{ height: "30px" }} onError={handleImageError} />

                              <Link to={`/single-product/${c.id}`} className="product_description">
                                {/* Nike Zoom Mercurial Vapor 15 Elite XXV FG */}
                                {c.product_description}
                              </Link>
                            </div>
                            {isMobile ? (
                              <div className="d-flex jc-end">
                                <p className="middle text-right">x {c.quantity_of_product}</p>
                                <p className="last ml-3">{c.total_price}</p>
                              </div>
                            ) : (
                              <>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                                  <p className="middle text-right">x {c.quantity_of_product}</p>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                                  <p className="last">{c.total_price}</p>
                                </div>
                              </>
                            )}
                            {/* </div> */}
                          </>
                        </div>
                      ))}




                    </div>
                    <ul className="list list_2">
                      <li>
                        <a href="#">
                          Subtotal
                          <span id="subtotal">$704.85</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Delivery
                          <span>Flat rate: $50.00</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Total
                          <span id="total">{totalOrderAmount}</span>
                        </a>
                      </li>
                    </ul>
                    <div className="payment_item">
                      <div className="radion_btn">
                        <input
                          type="radio"
                          id="f-option5"
                          name="selector"
                          value="Cash On Delivery"
                          checked
                        />
                        <label for="f-option5">Cash On Delivery</label>
                        <div className="check"></div>
                      </div>
                      <p>
                        Please try to be there when the order arrive and keep
                        the cash ready.
                      </p>
                    </div>
                    <button className="place_order_btn w-100">
                      Place Order
                    </button>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </section>
      {/* < !--================End Checkout Area ================= --> */}

      {/* <!--================ Subscription Area ================--> */}
      <Subscription />
      {/* <!--================ End Subscription Area ================--> */}
      {/* <Addaddress/> */}
      <Footer />
    </>
  );
}

export default Checkout;
