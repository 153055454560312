import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Filter from "../components/Filter";
import Subscription from "../components/Subscription";
import Footer from "../components/Footer";
import { FaRegHeart } from "react-icons/fa";
import { IoCartOutline } from "react-icons/io5";
import { filterProduct, getAllCategories, getAllVariationBasisOnVariant, getProduct, getProductByPages, } from "../ContextAPI/APIs/api";
import { Alert } from "../ContextAPI/Components/notify";


import { useProductFilter } from "../ContextAPI/Components/productFilter"
import ProductCard from "../components/ProductCard";


function Shop() {

  const { products, categories, variationOption, renderCount, setRenderCount, __filters, __setFilters, page, setPage, getVariationBasisOnVariant, getCategories, onPageLoadParamsEmpty, getAllProducts, handleProductFilter, handleSubmit, filterHandler } = useProductFilter()

  useEffect(() => {
    getVariationBasisOnVariant("color");
    getVariationBasisOnVariant("size");
    getVariationBasisOnVariant("material");
    getCategories();
  }, []);


  useEffect(() => {
    if (renderCount === 0) {
      onPageLoadParamsEmpty(__filters) ? getAllProducts() : handleProductFilter(); // Corrected function name
      console.log("On reload", __filters, onPageLoadParamsEmpty(__filters));
    } else handleSubmit();
  }, [renderCount]);


  return (
    <>
      <Navbar />

      {/* <!--================Home Banner Area =================--> */}
      <Banner BannerHeading="Shop" PageRoute="/shop" PageLink="Shop" />
      {/* <!--================End Home Banner Area =================--> */}


      {/* <!--================Category Product Area =================--> */}
      <section className="cat_product_area section_gap">
        <div className="container-fluid">
          <div className="row flex-row-reverse">
            <div className="col-lg-9 col-md-12">
              {/* <div className="product_top_bar d-flex flex-wrap justify-content-between">
                <div className="mob_filter">
                  <Filter />
                </div>
                <div className="left_dorp">
                  <select className="sorting form-select" onchange="sorting(value)">
                    <option value="1">Recently Added</option>
                    <option value="2">Low To High</option>
                    <option value="3">High To Low</option>
                  </select>
                </div>
              </div> */}

              {/* <div className="top_bar_border"></div> */}

              <div className="latest_product_inner row" id="categoryProductsDiv"  >

                {/* <!-- PRODUCTS VIA JavaScript --> */}
                {/* {products.map((product, i) => {
                  return <div key={i} className="col-lg-3 col-md-3 col-sm-4 col-12">
                    <div className="f_p_item">
                      <div className="f_p_img">
                        <Link to={`/single-product/${product.id}`}>
                          <img className="img-fluid" src={`${File_URL}/${product.image_url}`} alt="image" onError={handleImageError} />
                        </Link>

                        <div className="p_icon">
                          <a id=""><FaRegHeart className="p-icon" /></a>
                          <a className="cursor"><IoCartOutline className="p-icon" /></a>
                        </div>
                      </div>

                      <Link to={`/single-product/${product.id}`}>  <h4>{product.name}</h4> </Link>
                      <h5>${product.price}</h5>
                    </div>
                  </div>
                })} */}

                <ProductCard />

                <div className="d-flex jc-center m-auto">
                  <button className="load_more" onClick={() => setPage(page + 1)} >Load More</button>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-12 desk_filter">
              <div className="left_sidebar_area">
                <aside className="left_widgets cat_widgets">
                  <div className="l_w_title">
                    <h3>Filter Via Categories</h3>
                  </div>
                  <div className="widgets_inner">

                    {/* <!-- GENERATED THROUGH JavaScript --> */}
                    <ul className="list" id="genderList">
                      {categories?.map((option) => (
                        <li key={option.id} >
                          <a name="category" className={__filters.category.find(e => e.id)} value={option.id} onClick={(e) => filterHandler(e)} >{option.category_name}</a>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="l_w_title">
                    <h3>Filter Via Size</h3>
                  </div>
                  <div className="widgets_inner">
                    <ul className="list" id="categoriesList">
                      {variationOption.size.map((option) => (
                        <li key={option.id}>
                          <a className="cursor" name="size" value={option.id} onClick={(e) => filterHandler(e)}>{option.name}</a>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="l_w_title">
                    <h3>Filter Via Colors</h3>
                  </div>
                  <div className="p_filter_widgets">
                    <div className="widgets_inner">
                      <ul className="list" id="colorsList">
                        {variationOption.color.map((option) => (
                          <li key={option.id}>
                            <a className="cursor" name="color" value={option.id} onClick={(e) => filterHandler(e)} >{option.name}</a>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="l_w_title">
                      <h3>Filter Via Price</h3>
                    </div>
                    <div className="widgets_inner">
                      <div className="range_item">
                        <input type="range" className="form-range" min="1" max="500" step="1" value="0" id="customRange3" />

                        {/* <!-- <div id="slider-range" onmouseup="priceSort()"></div> --> */}

                        <div className="d-flex align-items-center m0">
                          <label className="mt-0 mb-0" for="amount">Price:</label>
                          <p className="mb-0 ml-2" id="priceChange">$500</p>
                        </div>

                      </div>

                      <div className="form-group">
                        <label className="form-label mb-0">Min Price</label>
                        <input type="number" className="form-control" placeholder="min price" value={__filters.minPrice} name="minPrice" onChange={(e) => { __setFilters({ ...__filters, ["minPrice"]: e.target.value }); setRenderCount((prev) => prev + 1) }} />
                      </div>

                      <div className="form-group">
                        <label className="form-label mb-0">Max Price</label>
                        <input type="number" placeholder="max price" className="form-control" value={__filters.maxPrice} name="maxPrice" onChange={(e) => { __setFilters({ ...__filters, ["maxPrice"]: e.target.value }); setRenderCount((prev) => prev + 1) }} />
                      </div>

                    </div>

                    <div className="widgets_inner text-center">
                      <a className="reset_filter cursor">Reset Filter</a>
                    </div>

                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--================End Category Product Area =================--> */}

      {/* <!--================ Subscription Area ================--> */}
      <Subscription />
      {/* <!--================ End Subscription Area ================--> */}

      <Footer />
    </>
  );
}

export default Shop;
