import React, { useEffect, useState } from 'react'
import { addProductImage, deleteProductImage, getImages } from '../ContextAPI/APIs/api'
import { Alert } from '../ContextAPI/Components/notify'
import { Gallery } from 'react-grid-gallery'
import { File_URL } from '../config'
import { useParams } from 'react-router-dom'
// import { message } from 'antd'

const GalleryImages = () => {
    const {id}=useParams()
    const [images, setImages] = useState([])
    const [productImage,setProductImage]= useState({
        product_id:id,
        image_url:""
    })

    const handleImageClick = (index) => {
        const parts = images[index].src.split('/');
        const imageName = parts[parts.length - 2] + '/' + parts[parts.length - 1];
        setProductImage({ ...productImage, image_url: imageName });
        console.log("vvvvvvvvvvvvvv",{ ...productImage, image_url: imageName})
    }

    const addImageOfProduct= async (e)=>{
        e.preventDefault()
        try {
            const response = await addProductImage(id,productImage)
            if(response.success) {
                return Alert(response.message,response.success)}
        } catch (error) {
            console.log(error)
        }
    }



   

    const getAllImages = async () => {
        try {
            const response = await getImages()
            if (response.success) return setImages((response.images).map(e =>  {
                return {
                    src: `${File_URL}/${e.image_url}`,
                    width: 200,
                    height: 250,
                }
            }))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllImages();
        
    }, [])


    return (
        <>
            {
                images.length > 0 &&
                <Gallery images={images} onClick={handleImageClick} />
            }
            <button className='btn-primary btn'  onClick={(e)=>{addImageOfProduct(e)}}>select</button>
        </>
    )
}

export default GalleryImages
