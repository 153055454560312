import React from 'react'
import AdminSidebar from '../components/AdminSidebar'

function AdminDashboard() {
    return (
        <>
            <div className="d-flex">
                <div className="sidebar_div">
                    <AdminSidebar />
                </div>
                <div className="page_div">
                    <section className="user_profile_area">
                        <h2>Admin Dashboard</h2>
                    </section>
                </div>
            </div>
        </>
    )
}

export default AdminDashboard
