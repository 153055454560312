import React from 'react'

import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useAuth } from '../../ContextAPI/Components/auth'

function Dashboard() {


    const {signOut}=useAuth()
    

    return (
        <>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar />
                </div>
                <div className="page_div">
                    <section className='user_dashboard_area'>
                        <div className="container">
                            <h2>Dashboard</h2>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Dashboard
