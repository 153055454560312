import React, { useEffect, useState } from 'react'
import NavSidebar from '../../components/Sidebar'
import { Alert } from '../../ContextAPI/Components/notify'
import { getAddreses } from '../../ContextAPI/APIs/api'
import { Button, Modal } from 'react-bootstrap'
import Addaddress from '../Addaddress'
import { useProSidebar } from 'react-pro-sidebar'
import { useMediaQuery } from 'react-responsive'
import { FaPlus } from 'react-icons/fa'

function Addresses() {

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const [address, setAddress] = useState([])
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const addresses = [
        // {
        //     address: "Gulshan",
        //     street_no: "101",
        //     city: "Karachi",
        //     country: "Pakistan",
        //     postal_code: "7457",
        //     region: "Block 4"
        // },
        // {
        //     address: "Gulshan",
        //     street_no: "101",
        //     city: "Karachi",
        //     country: "Pakistan",
        //     postal_code: "7457",
        //     region: "Block 4"
        // },
        // {
        //     address: "Gulshan",
        //     street_no: "101",
        //     city: "Karachi",
        //     country: "Pakistan",
        //     postal_code: "7457",
        //     region: "Block 4"
        // },
    ]


    const getAddressDataOfUser = async () => {
        try {
            const response = await getAddreses()
            if (response.success) setAddress(response.addreses)
        } catch (error) {
            console.log(error)
            Alert(error.message)
        }
    }
    useEffect(() => {
        getAddressDataOfUser()
    }, [])


    const isSmallMobile = useMediaQuery({ query: '(max-width: 501px)' });


    return (
        <>

            <Modal show={show} onHide={handleClose} centered>

                <Modal.Body>
                    <Addaddress />
                </Modal.Body>

            </Modal>

            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <section className="user_addresses_area">
                        <div className="container">

                            <div className="d-flex jc-between ai-center mb-4">
                                <h2 className='mb-0'>Addresses</h2>
                                <button className='add_address_btn' onClick={handleShow}>
                                    { isSmallMobile ? <FaPlus /> : "Add Address" }
                                </button>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">City</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Street No</th>
                                            <th scope="col">Country</th>
                                            <th scope="col">Postal Code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            address.map((data, i) => {
                                                return <>
                                                    <tr key={i}>
                                                        <th>{data.city}</th>
                                                        <td>{data.address_line1}</td>
                                                        <td>{data.street_number}</td>
                                                        <td>{data.country}</td>
                                                        <td>{data.postal_code}</td>
                                                    </tr>
                                                </>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Addresses
