import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const ThankYouPage = () => {

  return (
    <>

      <Navbar />

      <section className='thankyou_page d-flex ai-center'>
        <div className="container">
          <div className="card">
            <h2>Thank You For placing orders!!</h2>
            <Link to="/my-orders" className='submit_btn dark_hover_btn'>My Orders</Link>
          </div>
        </div>
      </section>

      <Footer />

    </>
  )
}

export default ThankYouPage
