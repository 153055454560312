import React from 'react'
import { GoArrowRight } from 'react-icons/go'
import { Link } from 'react-router-dom'

function Banner(props) {
    return (
        <>
            {/* <!--================Home Banner Area =================--> */}
            <section className="banner_area">
                <div className="banner_inner d-flex align-items-center">
                    <div className="container">
                        <div className="banner_content text-center">
                            <h2>{props.BannerHeading}</h2>
                            <div className="page_link">
                                <Link to="/">Home <GoArrowRight className='arrow_icon' /></Link>
                                <Link to={props.PageRoute}>{props.PageLink}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================End Home Banner Area =================--> */}

        </>
    )
}

export default Banner
