import { useState, useEffect } from "react";
import { getProduct } from "../../ContextAPI/APIs/api";
import { File_URL } from "../../config";
import { handleImageError } from "../../helpers/helper";

import NavSidebar from "../../components/Sidebar";
import AdminSidebar from "../../components/AdminSidebar";
import { Link } from "react-router-dom";
import formatter from "../../helpers/formatter";

const Products = () => {
  const [product, setProducts] = useState([]);

  async function GetProducts() {
    const res = await getProduct();
    if (res.product.length > 0) setProducts(res.product);
  }

  useEffect(() => {
    GetProducts();
  }, []);

  return (
    <>
      <div className="d-flex">
        <div className="sidebar_div">
          <AdminSidebar />
        </div>
        <div className="page_div">
          <section className="my_orders_area">
            <div className="container">
              <h2>Products</h2>

              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Status</th>
                      <th scope="col">Price</th>
                      <th scope="col">Total Quantity</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {product.map((item, i) => {
                      return (
                        <tr>
                          <th scope="row">{item.id}</th>
                          <td>
                            <img
                              src={`${File_URL}/${item.image_url}`}
                              alt={item.name}
                              style={{ maxWidth: "50px", marginRight: "10px" }}
                              onError={handleImageError}
                            />
                            {item.name}
                          </td>
                          <td
                            className={`${item.status == "active" ? "text-success" : "text-danger"}`}>
                            {item.status || "unactive"}
                          </td>
                          <td>{formatter.format(item.price)}</td>
                          <td>{item.total_quantity || "---"}</td>
                          <td>
                            <Link to={`/admin/edit-product/${item.id}`} className="btn-primary" >
                              Edit
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Products;
