import { createContext, useContext, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { filterProduct, getAllCategories, getAllVariationBasisOnVariant, getProduct } from "../APIs/api";


// Step 1
const ProductFilterContext = createContext()

// Step 2
export const useProductFilter = () => {
    return useContext(ProductFilterContext);
}

// Step 3
export const ProductFilterProvider = ({ children }) => {
    const navigate = useNavigate(); // Corrected spelling
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [renderCount, setRenderCount] = useState(0);
    const [__filters, __setFilters] = useState({
        category: queryParams.getAll("category") || [],
        color: queryParams.getAll("color") || [],
        size: queryParams.getAll("size") || [],
        material: queryParams.getAll("material") || [],
        minPrice: queryParams.get("minPrice") || "",
        maxPrice: queryParams.get("maxPrice") || "",
    });
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [variationOption, setVariationOption] = useState({
        color: [],
        size: [],
        material: [],
    });
    const [page, setPage] = useState(1);

    const getAllProducts = async () => {
        try {
            const response = await getProduct(); // API call
            console.log("response", response);
            if (response.success) setProducts(response?.product);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getCategories = async () => {
        console.log(">>>>>>>>>>>>>>>>>>>>");
        try {
            const response = await getAllCategories("simple"); // API call
            console.log(response);
            if (response.success) setCategories(response.categories);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getVariationBasisOnVariant = async (fieldName) => {
        try {
            const response = await getAllVariationBasisOnVariant(fieldName); // API call
            if (response.success)
                setVariationOption((prevState) => ({
                    ...prevState,
                    [fieldName]: response.variantOption,
                }));
        } catch (error) {
            console.error("Error fetching variation options:", error); // Log the error
        }
    };

    const filterHandler = (e) => {
        const { name, value } = e.target;

        if (!__filters[name].includes(value)) {
            __setFilters({ ...__filters, [name]: [...__filters[name], value] });
        } else {
            __setFilters({
                ...__filters,
                [name]: __filters[name].filter((e) => e !== value),
            });
        }
        setRenderCount((prev) => prev + 1);
    };

    const handleSubmit = () => {
        let query = new URLSearchParams(location.search);
        console.log(__filters);

        for (const key in __filters) {
            query.delete(key);
            const f = __filters[key];

            if (Array.isArray(f)) {
                f.forEach((_f) => {
                    query.append(key, _f);
                });
            } else if (f !== "") {
                query.append(key, f);
            }
        }

        handleProductFilter(); // Call before navigating
        navigate(`?${query}`);
    };

    const handleProductFilter = async () => {
        try {
            const response = await filterProduct(__filters); // API call
            if (response.success) setProducts(response?.filteredProducts);
        } catch (error) {
            console.error("Error filtering products:", error); // Log the error
        }
    };



    function onPageLoadParamsEmpty(obj) {
        for (const key in obj) {
            if (
                (Array.isArray(obj[key]) && obj[key].length > 0) ||
                (typeof obj[key] === "string" && obj[key].trim() !== "")
            ) {
                return false; // If any array key has non-zero length or any string key is non-empty, return false
            }
        }
        return true; // All keys are empty
    }



    return (
        <ProductFilterContext.Provider value={{ 
            products, categories, variationOption, renderCount, setRenderCount, __filters, __setFilters, page, setPage, getVariationBasisOnVariant, getCategories, onPageLoadParamsEmpty, getAllProducts, handleProductFilter, handleSubmit, filterHandler
         }}>
            {children}
        </ProductFilterContext.Provider>
    )
}