import { createContext, useContext, useState, useEffect } from "react"
// import { getPlatform } from "../APIs/api";



// Step 1
const PlatformContext = createContext()

// Step 2
export const usePlatform = () => {
    return useContext(PlatformContext);
}

// Step 3
export const PlatformProvider = ({ children }) => {
    const [plaform, setPlatform] = useState({
        platform_name: "",
        logoLong: "",
        logoShort: "",
        tagline: "",
        BG1: "",
        BG2: "",
        green: "",
        red: "",
        blue: "",
        textColor1: "",
        textColor2: "",
        Terms_Cond: "",
        Privacy_policy: "",
        LegalInfo: "",
    });

    const getPlatformData = async () => {
        try {
          // const response = await getPlatform()
          // console.log(response.message)
          // if (response.success) {
          //   setPlatform(response.message)
    
          //   document.documentElement.style.setProperty("--bodyColor", response.message.BG1);
          //   document.documentElement.style.setProperty("--cardColor", response.message.BG2);
          //   document.documentElement.style.setProperty("--textColor", response.message.textColor1);
          //   document.documentElement.style.setProperty("--sidebarLinkColor", response.message.textColor2);
          // }
        }
        catch (e) {
          console.log(e);
        }
      }

    return (
        <PlatformContext.Provider value={{ plaform, setPlatform, getPlatformData }}>
            {children}
        </PlatformContext.Provider>
    )
}