// // Development Version
// const API_URL = "http://localhost:3001"
// const FrontAPI_URL = "http://localhost:3000"


// Production Version
const API_URL = "https://api.ecprofessionals.co"
const FrontAPI_URL = "https://api.ecprofessionals.co"


// For Both
const File_URL = `${API_URL}/assets/files`



export { API_URL, FrontAPI_URL, File_URL }