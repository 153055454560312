import React from 'react'

function Subscription() {
    return (
        <>
            {/* <!--================ Subscription Area ================--> */}
            <section class="subscription-area section_gap" id="contact">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="section-title text-center">
                                <h2>Subscribe for Our Newsletter</h2>
                                <span>We won’t send any kind of spam</span>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <div id="mc_embed_signup">
                                <form class="subscription relative">
                                    <input type="email" id="newsletterEmail" placeholder="Email address"
                                        onfocus="this.placeholder = ''" onblur="this.placeholder = 'Email address'" required />
                                        {/* <!-- <div style="position: absolute; left: -5000px;">
                                            <input type="text" name="b_36c4fd991d266f23781ded980_aefe40901a" tabindex="-1" value="">
                                        </div> --> */}
                                        <button type="submit" class="newsl-btn" onclick="emailSubmit()">Get Started</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================ End Subscription Area ================--> */}
        </>
    )
}

export default Subscription
