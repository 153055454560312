import React, { useEffect, useState } from "react";
import { addCategory, getAllCategories } from "../ContextAPI/APIs/api";
import { Alert } from "../ContextAPI/Components/notify";
import AdminSidebar from "../components/AdminSidebar";

const AddCategory = () => {

  const [categories, setCategories] = useState([]);

  const [formData, setFormData] = useState({
    category_name: "",
    parent_category_id: "",
  });

  // get all categories

  const AddCategory = async (e) => {
    e.preventDefault()
    try {
      const response = await addCategory(formData)
      if (response.success) return Alert(response.message)
    } catch (error) {
      console.log(error);
      Alert(error.message)
    }
  }


  const getCategories = async () => {
    console.log(">>>>>>>>>>>>>>>>>>>>");
    try {
      const response = await getAllCategories("simple");
      console.log(response);
      if (response.success) setCategories(response.categories);
    } catch (error) {
      console.log("errro", error);
      Alert(error.message, "error");
    }
  };

  useEffect(() => {
    getCategories();
  }, []);


  return (
    <>

      <div className="d-flex">
        <div className="sidebar_div">
          <AdminSidebar />
        </div>
        <div className="page_div">
          <section className="admin_pages add_category_area">
            <div className="container">
              <div className="card">
                <h3>Add Category</h3>
                <form className="login_form" onSubmit={(e) => AddCategory(e)} id="contactForm" >
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Category Name"
                      value={formData.category_name}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          category_name: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">Parent Category (if any)</label>
                    <select
                      className="form-select"
                      value={formData.parent_category_id}
                      onChange={(e) =>
                        setFormData({ ...formData, parent_category_id: e.target.value })
                      }
                    >
                      <option value="">Select</option>
                      {categories.map((c) => (
                        <option key={c.id} value={c.id}>
                          {c.id} {c.category_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-0">
                    <button type="submit" value="submit" className="submit_btn" >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
