import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import PRODUCT from "../../assets/images/NIKE AIR JORDAN MID/1/zzmfk3kwuw3ktkilxfnp.webp";
import { FaRegHeart } from 'react-icons/fa';
import { IoCartOutline } from 'react-icons/io5';


function NewArrivalsSlider() {

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    centerPadding: "50px",
                    slidesToShow: 3,
                }
            }, {
                breakpoint: 992,
                settings: {
                    centerPadding: "50px",
                    slidesToShow: 3,
                }
            }, {
                breakpoint: 768,
                settings: {
                    centerPadding: "50px",
                    slidesToShow: 3,
                }
            }, {
                breakpoint: 576,
                settings: {
                    centerMode: false,
                    slidesToShow: 2,
                    centerPadding: "40px",
                }
            }, {
                breakpoint: 380,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    centerPadding: "40px",
                }
            }
        ]
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
                <div className="px-3">
                    <a href="#">
                        <div className="f_p_item">
                            <div className="f_p_img">
                                <img className="img-fluid" src={PRODUCT} alt="image" />
                                <div className="curve_box">
                                    <div className="p_icon">
                                        <a href=''>
                                            <FaRegHeart className='p-icon' />
                                        </a>
                                        <a href=''>
                                            <IoCartOutline className='p-icon' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="f_p_body">
                                <h4 className='p_name'>Air Jordan 1 Mid SE</h4>
                                <div className="d-flex jc-center ai-center mb-2">
                                    <div className="small-ratings me-2">
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                    <h4 className="review">1 review</h4>
                                </div>
                                <h5>$124.95</h5>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="px-3">
                    <a href="#">
                        <div className="f_p_item">
                            <div className="f_p_img">
                                <img className="img-fluid" src={PRODUCT} alt="image" />
                                <div className="curve_box">
                                    <div className="p_icon">
                                        <a href=''>
                                            <FaRegHeart className='p-icon' />
                                        </a>
                                        <a href=''>
                                            <IoCartOutline className='p-icon' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="f_p_body">
                                <h4 className='p_name'>Air Jordan 1 Mid SE</h4>
                                <div className="d-flex jc-center ai-center mb-2">
                                    <div className="small-ratings me-2">
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                    <h4 className="review">1 review</h4>
                                </div>
                                <h5>$124.95</h5>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="px-3">
                    <a href="#">
                        <div className="f_p_item">
                            <div className="f_p_img">
                                <img className="img-fluid" src={PRODUCT} alt="image" />
                                <div className="curve_box">
                                    <div className="p_icon">
                                        <a href=''>
                                            <FaRegHeart className='p-icon' />
                                        </a>
                                        <a href=''>
                                            <IoCartOutline className='p-icon' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="f_p_body">
                                <h4 className='p_name'>Air Jordan 1 Mid SE</h4>
                                <div className="d-flex jc-center ai-center mb-2">
                                    <div className="small-ratings me-2">
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                    <h4 className="review">1 review</h4>
                                </div>
                                <h5>$124.95</h5>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="px-3">
                    <a href="#">
                        <div className="f_p_item">
                            <div className="f_p_img">
                                <img className="img-fluid" src={PRODUCT} alt="image" />
                                <div className="curve_box">
                                    <div className="p_icon">
                                        <a href=''>
                                            <FaRegHeart className='p-icon' />
                                        </a>
                                        <a href=''>
                                            <IoCartOutline className='p-icon' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="f_p_body">
                                <h4 className='p_name'>Air Jordan 1 Mid SE</h4>
                                <div className="d-flex jc-center ai-center mb-2">
                                    <div className="small-ratings me-2">
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                    <h4 className="review">1 review</h4>
                                </div>
                                <h5>$124.95</h5>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="px-3">
                    <a href="#">
                        <div className="f_p_item">
                            <div className="f_p_img">
                                <img className="img-fluid" src={PRODUCT} alt="image" />
                                <div className="curve_box">
                                    <div className="p_icon">
                                        <a href=''>
                                            <FaRegHeart className='p-icon' />
                                        </a>
                                        <a href=''>
                                            <IoCartOutline className='p-icon' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="f_p_body">
                                <h4 className='p_name'>Air Jordan 1 Mid SE</h4>
                                <div className="d-flex jc-center ai-center mb-2">
                                    <div className="small-ratings me-2">
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                    <h4 className="review">1 review</h4>
                                </div>
                                <h5>$124.95</h5>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="px-3">
                    <a href="#">
                        <div className="f_p_item">
                            <div className="f_p_img">
                                <img className="img-fluid" src={PRODUCT} alt="image" />
                                <div className="curve_box">
                                    <div className="p_icon">
                                        <a href=''>
                                            <FaRegHeart className='p-icon' />
                                        </a>
                                        <a href=''>
                                            <IoCartOutline className='p-icon' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="f_p_body">
                                <h4 className='p_name'>Air Jordan 1 Mid SE</h4>
                                <div className="d-flex jc-center ai-center mb-2">
                                    <div className="small-ratings me-2">
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star rating-color"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                    <h4 className="review">1 review</h4>
                                </div>
                                <h5>$124.95</h5>
                            </div>
                        </div>
                    </a>
                </div>
            </Slider>
        </div>
    );
}

export default NewArrivalsSlider;
