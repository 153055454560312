import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Subscription from '../components/Subscription'
import Banner from '../components/Banner'
import Footer from '../components/Footer'
import { HiArrowSmLeft } from 'react-icons/hi'

function Wishlist() {

    const [totalOrderAmount, setTotalOrderAmount] = useState(0);

    return (
        <>

            <Navbar />

            {/* <!--================Home Banner Area =================--> */}
            <Banner BannerHeading="Wishlist" PageRoute="/wishlist" PageLink="Wishlist" />
            {/* <!--================End Home Banner Area =================--> */}


            {/* <!--================Cart Area =================--> */}
            <section className="cart_area">
                <div className="container">
                    <div className="cart_inner">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Product</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Add To Cart</th>
                                        <th scope="col">Remove</th>
                                    </tr>
                                </thead>
                                <tbody id="wishlistProductDiv">
                                    {/* <!-- PRODUCTS GENERETED THROUGH JavaScript --> */}

                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <h5>Subtotal</h5>
                                        </td>
                                        <td>
                                            <h5 id="subtotal">{totalOrderAmount}</h5>
                                        </td>
                                    </tr>

                                    {/* <tr className="out_button_area">
                                    </tr> */}

                                </tbody>

                            </table>
                        </div>

                        <div className="checkout_btn_inner mt-4">
                            <Link className="gray_btn" to="/shop">
                                <HiArrowSmLeft className="arrow_icon me-2" />
                                Continue Shopping
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================End Cart Area =================--> */}


            {/* <!--================ Subscription Area ================--> */}
            <Subscription />
            {/* <!--================ End Subscription Area ================--> */}

            <Footer />

        </>
    )
}

export default Wishlist
