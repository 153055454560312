import { CombineComponents } from "./CombineContext";
import { AuthProvider } from "./Components/auth";
import { ToastProvider } from "./Components/notify";
import { LoaderProvider } from "./Components/loader";

// Extra
import { ThemeProvider } from "./Components/apexchartDarkLightMode";
import { PlatformProvider } from "./Components/platform";
import { PathnameProvider } from "./Components/PathnameContext";
import { ProductFilterProvider } from "./Components/productFilter";

const providers = [
    AuthProvider,
    ToastProvider,
    LoaderProvider,
    PathnameProvider,
    ProductFilterProvider,
    
    ThemeProvider,
    PlatformProvider,
]

export const AppContextProvider = CombineComponents(...providers)