import React, { useEffect, useState } from "react";
import { addProduct, getAllCategories } from "../../ContextAPI/APIs/api";
import { Alert } from "../../ContextAPI/Components/notify";
import { useNavigate } from "react-router-dom";
import AdminSidebar from "../../components/AdminSidebar";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const AddProducts = () => {

  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    short_description: "",
    long_description:"",
    price: "",
    stock_management: "",
    total_quantity: "",
    SKU: "",
    category_id: "",
  });

  //   get categories function
  const getCategories = async () => {
    console.log(">>>>>>>>>>>>>>>>>>>>");
    try {
      const response = await getAllCategories({type: "simple"});
      console.log(response);
      if (response.success) setCategories(response.categories);
    } catch (error) {
      console.log("errro", error);
      Alert(error.message, "error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addProduct(formData);
      if (response.success) {
        Alert(response.message);
        navigate(`/admin/add-product-variant/${response.data.insertId}/${formData.category_id}`);
      }
    } catch (error) {
      console.log(error);
      Alert(error.message);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>

      <div className="d-flex">
        <div className="sidebar_div">
          <AdminSidebar />
        </div>
        <div className="page_div">
          <section className="admin_pages add_products_area">
            <div className="container">
              <div className="card">
                <h3>Add Product</h3>
                <form className="login_form" id="contactForm" onSubmit={(e) => handleSubmit(e)} >
                  <div className="form-group">
                    <input type="text" className="form-control" id="name" name="name" placeholder="Product Name" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} required />
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" id="description" name="short_description" placeholder="Product Description" value={formData.short_description} onChange={(e) => setFormData({ ...formData, short_description: e.target.value })} required />
                  </div>
                  <div className="form-group">
                  <CKEditor
                                                editor={ClassicEditor}
                                                data={formData.long_description}
                                                onReady={editor => {
                                                    // Add a class name to the CKEditor content element

                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        long_description: data,
                                                    }));
                                                    // setEditorData(data); // Update editorData state
                                                    // console.log({ event, editor, data });
                                                }}
                                                onBlur={(event, editor) => {
                                                    // console.log("Blur.", editor);
                                                }}
                                                onFocus={(event, editor) => {
                                                    // console.log('Focus', editor);
                                                }}
                                            />
                    {/* <input type="text" className="form-control" id="description" name="long_description" placeholder="Product detailed Description" value={formData.long_description} onChange={(e) => setFormData({ ...formData, long_description: e.target.value })} required /> */}
                  </div>
                  <div className="form-group">
                    <input type="number" className="form-control" id="price" name="price" placeholder="Price" value={formData.price} onChange={(e) => setFormData({ ...formData, price: e.target.value })} required />
                  </div>
                  <div className="form-group">
                    <select className="form-select" value={formData.stock_management} onChange={(e) => setFormData({ ...formData, stock_management: e.target.value, })} >
                      <option value="">Choose Stock Management</option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                  </div>
                  {
                    formData.stock_management == 1 &&
                    <div className="form-group">
                      <input type="number" className="form-control" id="total_quantity" name="total_quantity" placeholder="Total Quantity" value={formData.total_quantity} onChange={(e) => setFormData({ ...formData, total_quantity: e.target.value, })} required />
                    </div>
                  }
                  <div className="form-group">
                    <input type="text" className="form-control" id="SKU" name="SKU" placeholder="SKU" value={formData.SKU} onChange={(e) => setFormData({ ...formData, SKU: e.target.value })} required />
                  </div>
                  <div className="form-group">
                    <select className="form-select" value={formData.category_id} onChange={(e) => setFormData({ ...formData, category_id: e.target.value })}>
                      <option value="">Choose Category</option>
                      {categories.map((c) => (
                        <option key={c.id} value={c.id}>   {c.category_name} </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-0">
                    <button type="submit" value="submit" className="submit_btn" >   Add </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default AddProducts;
