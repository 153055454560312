import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

function Page_404() {
  return (
    <>
      <Navbar />

      <section className='page_404 d-flex ai-center'>
        <div className="container">
          <div className="card">
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <Link to="/" className='submit_btn dark_hover_btn'>Back to Home</Link>
          </div>
        </div>
      </section>

      <Footer />

    </>
  )
}

export default Page_404
