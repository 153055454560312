import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import { useAuth } from '../ContextAPI/Components/auth';

import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar, sidebarClasses } from 'react-pro-sidebar';

import { HiBars3, HiOutlineUserCircle } from "react-icons/hi2";
import { TbCategoryPlus, TbLogout2 } from 'react-icons/tb';
import { IoBagAddOutline, IoBagOutline, IoBagHandleOutline, IoHomeOutline, IoLocationOutline } from 'react-icons/io5';
import { AiFillProduct } from "react-icons/ai";

import logo from "../assets/images/Nike.png"
import logo2 from "../assets/images/Nike.png"
import { usePathname } from '../ContextAPI/Components/PathnameContext';
import { LuImagePlus } from 'react-icons/lu';
import { FaUikit } from 'react-icons/fa';


function AdminSidebar({ collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl }) {

    const { user, SignOut } = useAuth();

    const { pathname: currentPath } = useLocation();

    const { style } = usePathname();
    const navigate = useNavigate()

    const isSmallMobile = useMediaQuery({ query: '(max-width: 501px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    function newfunction() {
        const sidebar = window.document.getElementById("sideBar")
        console.log("siderbar", sidebar);
        sidebar.style.height = "100%"
        sidebar.style.zIndex = "1"
        if (collapsed) {
            sidebar.style.position = "fixed"
            sidebar.style.width = "50%"
        }
        else {
            sidebar.style.position = "unset"
        }
    }


    return (
        <>
            <div className='sidebar_main'>
                <Sidebar
                    // defaultCollapsed={isTablet ? true : false}
                    defaultCollapsed={false}
                    id="sideBar"
                    className='navsidebar'
                    rootStyles={{
                        [`.${sidebarClasses.container}`]: {
                            padding: "10px",
                            width: "100%"
                        },
                    }}
                >
                    <Menu
                        menuItemStyles={{
                            button: ({ level, active, disabled }) => {
                                // only apply styles on first level elements of the tree
                                if (level == 0)
                                    return {
                                        color: disabled ? '#f5d9ff' : '#d359ff',
                                        backgroundColor: active ? '#eecef9' : undefined,
                                        paddingRight: "5px",
                                        paddingLeft: "5px"
                                    };
                            },
                        }}
                    >

                        <MenuItem className='py-3 sidebar_toggle'>
                            {/* <div className='d-flex jc-between ai-center'> */}
                            {
                                collapsed ?
                                    <Link to="/" className="activeLink1 hash-tag text-truncate">
                                        <img src={logo2} alt="logo" className='img-fluid' style={{ width: "40px" }} />
                                    </Link>
                                    :
                                    <>
                                        <Link to={"/"} className="" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                            <img src={logo} alt="logo" className='img-fluid' style={{ width: "50px" }} />
                                        </Link>
                                    </>
                            }
                            {/* <main className="oo78uijk">
                                    {
                                        isSmallMobile ?
                                            <button className="btn btnFocus" onClick={() => { collapseSidebar(); console.log("collapsed", collapsed); newfunction() }}><HiBars3 className="mr-1" style={{ color: "#b1bad3", fontSize: "25px" }} /></button>
                                            :
                                            <button className="btn btnFocus" onClick={() => { collapseSidebar(); console.log("collapsed", collapsed) }}><HiBars3 className="mr-1" style={{ color: "#b1bad3", fontSize: "25px" }} /></button>
                                    }
                                </main> */}
                            {/* </div> */}
                        </MenuItem>

                        {/* <h6>GLOBAL</h6> */}
                        <Link to="/admin/dashboard" className='link'>
                            <MenuItem className={`${style == "admin_dashboard" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<IoHomeOutline className={`${style == "admin_dashboard" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                <Link to="/admin/dashboard" className={`mx-1 my-1 ${style == "admin_dashboard" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Dashboard </Link>
                            </MenuItem>
                        </Link>
                        <Link to="/admin/products" className='link'>
                            <MenuItem className={`${style == "products" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<IoBagOutline className={`${style == "products" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                <Link to="/admin/products" className={`mx-1 my-1 ${style == "products" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Products </Link>
                            </MenuItem>
                        </Link>
                        <Link to="/admin/add-product" className='link'>
                            <MenuItem className={`${style == "get_product" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<IoBagAddOutline className={`${style == "get_product" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                <Link to="/admin/add-product" className={`mx-1 my-1 ${style == "get_product" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Add Products </Link>
                            </MenuItem>
                        </Link>
                        <Link to="/admin/add-images/:id" className='link'>
                            <MenuItem className={`${style == "add_images" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<LuImagePlus className={`${style == "add_images" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                <Link to="/admin/add-images/:id" className={`mx-1 my-1 ${style == "add_images" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Add Images </Link>
                            </MenuItem>
                        </Link>
                        <Link to="/admin/add-product-category" className='link'>
                            <MenuItem className={`${style == "add_product_category" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<TbCategoryPlus className={`${style == "add_product_category" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                <Link to="/admin/add-product-category" className={`mx-1 my-1 ${style == "add_product_category" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Add Category </Link>
                            </MenuItem>
                        </Link>
                        <Link to="/admin/add-variant" className='link'>
                            <MenuItem className={`${style == "add_variant" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<IoBagAddOutline className={`${style == "add_variant" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                <Link to="/admin/add-variant" className={`mx-1 my-1 ${style == "add_variant" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Add Variant </Link>
                            </MenuItem>
                        </Link>
                        <Link to="/admin/add-product-variant/:id/:category" className='link'>
                            <MenuItem className={`${style == "add_product_variant" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<IoBagAddOutline className={`${style == "add_product_variant" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                <Link to="/admin/add-product-variant/:id/:category" className={`mx-1 my-1 ${style == "add_product_variant" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Add Product Variant </Link>
                            </MenuItem>
                        </Link>

                        <Link to="/admin/ui-kit" className='link'>
                            <MenuItem className={`${style == "ui_kit" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<FaUikit className={`${style == "ui_kit" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                <Link to="/admin/ui-kit" className={`mx-1 my-1 ${style == "ui_kit" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> UI Kit </Link>
                            </MenuItem>
                        </Link>


                        <MenuItem className={`${style == "log_out" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<TbLogout2 className={`${style == "log_out" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                            <button
                                className={`mx-1 my-1 log_out_btn ${style == "log_out" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`}
                                style={{ width: "maxContent" }}
                                onClick={() => SignOut()}
                            >
                                Log Out
                            </button>
                        </MenuItem>
                    </Menu>

                </Sidebar>
            </div>
        </>
    )
}

export default AdminSidebar