import React, { useEffect, useState } from 'react'
import NavSidebar from '../../components/Sidebar'
import { Link } from 'react-router-dom'

import { getOrders } from '../../ContextAPI/APIs/api'
import { Alert } from '../../ContextAPI/Components/notify'
import { useProSidebar } from 'react-pro-sidebar'

function MyOrders() {

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const [orders, setOrders] = useState([])
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0)
    console.log('pageeeee', page)
    console.log('total', total)

    const getOrdersData = async () => {
        try {
            const response = await getOrders(page)
            if (response.success) setOrders([...orders, ...response.orders])
            setTotal(([...orders, ...response.orders]).length)

        } catch (error) {
            console.log(error)
            Alert(error.message)
        }
    }

    useEffect(() => {
        getOrdersData()
    }, [page])

    return (
        <>
            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <section className="my_orders_area">
                        <div className="container">

                            <h2>My Orders</h2>

                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Order ID</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Name</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            orders.map((data, i) => {
                                                return <>
                                                    <tr key={i}>
                                                        <th>{data.id}</th>
                                                        <td>{data.order_total}</td>
                                                        <td>{data.order_date}</td>
                                                        <td>{data.order_status_id}</td>
                                                        <td>{data.email}</td>
                                                        <td>{data.first_name}</td>
                                                        <td>
                                                            <Link to={`/tracking/${data.id}`}>
                                                                <button className='order_detail_btn'>Detail</button>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </>
                                            })
                                        }
                                    </tbody>
                                </table>

                                <div className='d-flex jc-center'>
                                    <button className='load_more' onClick={() => setPage(page + 1)}>Show more</button>
                                </div>

                            </div>

                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default MyOrders
