import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import Subscription from "../components/Subscription"

import ClientsSlider from '../components/Sliders/ClientsSlider';
import CategorySlider from '../components/Sliders/CategorySlider';

import h_deal_1 from "../assets/images/Hot Deals/deal-1.webp"
import h_deal_2 from "../assets/images/Hot Deals/deal-2.webp"
import h_deal_3 from "../assets/images/Hot Deals/deal-3.webp"

import collection_1 from "../assets/images/Collection/collection-shoes.webp"
import collection_2 from "../assets/images/Collection/collection-banner.webp"

import post_1 from "../assets/images/Posts/post-1.webp"
import post_2 from "../assets/images/Posts/post-2.webp"
import post_3 from "../assets/images/Posts/post-3.webp"

import about from "../assets/images/about.webp"

import { items as featureProduct } from "../js/items"
import { FaRegHeart } from 'react-icons/fa';
import { IoCartOutline } from 'react-icons/io5';
import ProductCard from '../components/ProductCard';
import NewArrivalsSlider from '../components/Sliders/NewArrivalsSlider';


function Home() {

    const [isFeaturedProduct, setIsFeaturedProduct] = useState("womens")

    return (
        <>

            <Navbar />

            {/* <!--================Home Banner Area =================--> */}
            <section className="home_banner_area">
                <div className="banner_inner d-flex align-items-center">
                    <div className="container-fluid">
                        <div className="banner_content row">
                            <div className="col-lg-8">
                                <p>Online Exclusive</p>
                                <h3>Fashion for
                                    <br /> Everyone!
                                </h3>
                                {/* <p>Fashion For Everyone is a celebration of diversity, inclusivity, and self-expression. It embraces individuality, empowering people to express their unique style and break free from societal norms.</p> */}
                                <Link className="white_bg_btn" to="/shop">View Collection</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================End Home Banner Area =================--> */}


            {/* <!--================Clients Logo Area =================--> */}
            <section className="clients_logo_area pad_top">
                <div className="container-fluid">

                    <ClientsSlider />

                </div>
            </section>
            {/* <!--================End Clients Logo Area =================--> */}


            {/* <!--================Categories Area =================--> */}
            <section className="categories_area section_gap">
                <div className="container-fluid">

                    <CategorySlider />

                </div>
            </section>
            {/* <!--================End Categories Area=================--> */}


            {/* <!--================Hot Deals Area =================--> */}
            {/* <section className="hot_deals_area section_gap">
                <div className="container-fluid">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="hot_deal_box">
                                <img className="img-fluid" src={h_deal_1} alt="image" />
                                <div className="content">
                                    <p>Find Your</p>
                                    <h2>Women's <br /> Sports Shoes</h2>
                                    <h4 className="price">$199.95</h4>
                                    <Link to="/shop" className="white_bg_btn">Shop Now <i className="fa-solid fa-arrow-right ml-1"></i></Link>
                                </div>
                                <Link className="hot_deal_link" to="/shop"></Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="hot_deal_box">
                                <img className="img-fluid" src={h_deal_2} alt="image" />
                                <div className="content">
                                    <p>Special Offer</p>
                                    <h2>Men's Sports <br /> Shoes</h2>
                                    <h4 className="price">$199.95</h4>
                                    <Link to="/shop" className="white_bg_btn">Shop Now <i className="fa-solid fa-arrow-right ml-1"></i></Link>
                                </div>
                                <Link className="hot_deal_link" to="/shop"></Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="hot_deal_box">
                                <img className="img-fluid" src={h_deal_3} alt="image" />
                                <div className="content">
                                    <p>Sale up to 30% off</p>
                                    <h2>Run Through <br /> Winter</h2>
                                    <h4 className="price">$199.95</h4>
                                    <Link to="/shop" className="white_bg_btn">Shop Now <i className="fa-solid fa-arrow-right ml-1"></i></Link>
                                </div>
                                <Link className="hot_deal_link" to="/shop"></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--================End Hot Deals Area =================--> */}


            {/* <!--================New Arrivals Area =================--> */}
            <section className="new_arrivals_area section_gap" >
                <div className="main_box">
                    <div className="container-fluid">
                        <div className="main_title">
                            <h2>New Arrivals</h2>
                            {/* <p>Who are in extremely love with eco friendly system.</p> */}
                        </div>
                    </div>

                    <div className="container-fluid">
                        <NewArrivalsSlider />
                    </div>


                </div>
            </section>
            {/* <!--================End New Arrivals Area =================--> */}


            {/* <!--================Single Product Area =================--> */}
            <section className="home_single_product_area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 s_product_bg">
                            <div className="home_single_product">
                                <div className="h_product_info">
                                    <p>SPRING COLLECION 2021</p>
                                    <h4><span>Shop The <br /> Collections</span></h4>
                                </div>
                                <a href="/single-product.html?name=${items[i].name}">
                                    <div className="f_p_item">
                                        <div className="f_p_img">
                                            <img className="img-fluid" src={collection_1} alt="image" />
                                            <div className="curve_box" style={{ backgroundColor: "#ededed" }}>
                                                <div className="p_icon">
                                                    <a href=''>
                                                        <FaRegHeart className='p-icon' />
                                                    </a>
                                                    <a href=''>
                                                        <IoCartOutline className='p-icon' />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="f_p_body">
                                            <h4>Air Jordan 1 Mid SE</h4>
                                            <div className="d-flex jc-center ai-center mb-2">
                                                <div className="small-ratings me-2">
                                                    <i className="fa fa-star rating-color"></i>
                                                    <i className="fa fa-star rating-color"></i>
                                                    <i className="fa fa-star rating-color"></i>
                                                    <i className="fa fa-star"></i>
                                                    <i className="fa fa-star"></i>
                                                </div>
                                                <h4 className="review">1 review</h4>
                                            </div>
                                            <h5>$124.95</h5>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <img src={collection_2} alt="image" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================End Single Product Area =================--> */}


            {/* <!--================Feature Product Area =================--> */}
            <section className="feature_product_area section_gap" >
                <div className="main_box">
                    <div className="container-fluid">
                        <div className="main_title">
                            <h2>Featured Products</h2>
                            <p>Who are in extremely love with eco friendly system.</p>
                        </div>

                        <div className="product_tab">

                            <ul className="nav nav-tabs nav-tabs-1 mb-4">
                                <li className="nav-item">
                                    <a className={`nav-link ${isFeaturedProduct == "womens" && "active"}`} onClick={() => setIsFeaturedProduct("womens")} href="#womens">
                                        WOMEN'S shop
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${isFeaturedProduct == "mens" && "active"}`} onClick={() => setIsFeaturedProduct("mens")} href="#mens">
                                        MEN'S shop
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${isFeaturedProduct == "kids" && "active"}`} onClick={() => setIsFeaturedProduct("kids")} href="#kids">
                                        Kid's shop
                                    </a>
                                </li>
                            </ul>

                        </div>

                        {
                            isFeaturedProduct == "womens" &&
                            <div className="row">

                                <ProductCard />

                                {/* {
                                    featureProduct.map((product, i) => {
                                        if (i <= 3) {
                                            return <>
                                                <div key={i} className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <Link to="/single-product">
                                                        <div className="f_p_item">
                                                            <div className="f_p_img">
                                                                <img className="img-fluid" src="https://fashiop.netlify.app/images/Womens/Jordans/Women_s%20a-19.jpg" alt="image" />
                                                                <div className="curve_box">
                                                                    <div className="p_icon">
                                                                        <a href=''>
                                                                            <FaRegHeart className='p-icon' />
                                                                        </a>
                                                                        <a href=''>
                                                                            <IoCartOutline className='p-icon' />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h4 className='p_name'>{product.name}</h4>
                                                            <div className="d-flex justify-content-center align-items-center mb-1">
                                                                <div className="small-ratings me-2">
                                                                    <i className="fa fa-star rating-color"></i>
                                                                    <i className="fa fa-star rating-color"></i>
                                                                    <i className="fa fa-star rating-color"></i>
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i>
                                                                </div>
                                                                <h4 className="review">1 review</h4>
                                                            </div>
                                                            <h5>${product.price}</h5>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </>
                                        }
                                    })
                                } */}
                            </div>
                        }

                        {
                            isFeaturedProduct == "mens" &&
                            <div className="row">

                                <ProductCard />

                                {/* {
                                    featureProduct.map((product, i) => {
                                        if (i <= 2) {
                                            return <>
                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="f_p_item">
                                                        <div className="f_p_img">
                                                            <Link to="/single-product">
                                                                <img className="img-fluid" src="https://fashiop.netlify.app/images/NIKE%20DUNK%20LOW/1/1.webp" alt="image" />
                                                            </Link>
                                                            <div className="curve_box">
                                                                <div className="p_icon">
                                                                    <a href=''>
                                                                        <FaRegHeart className='p-icon' />
                                                                    </a>
                                                                    <a href=''>
                                                                        <IoCartOutline className='p-icon' />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <a href="/single-product.html?name=${items[i].name}">
                                                            <h4>{product.name}</h4>
                                                        </a>
                                                        <div className="d-flex justify-content-center align-items-center mb-1">
                                                            <div className="small-ratings me-2">
                                                                <i className="fa fa-star rating-color"></i>
                                                                <i className="fa fa-star rating-color"></i>
                                                                <i className="fa fa-star rating-color"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                            </div>
                                                            <h4 className="review">1 review</h4>
                                                        </div>
                                                        <h5>${product.price}</h5>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    })
                                } */}
                            </div>
                        }

                        {
                            isFeaturedProduct == "kids" &&
                            <div className="row">

                                <ProductCard />

                                {/* {
                                    featureProduct.map((product, i) => {
                                        if (i <= 1) {
                                            return <>
                                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                    <div className="f_p_item">
                                                        <div className="f_p_img">
                                                            <Link to="/single-product">
                                                                <img className="img-fluid" src="https://fashiop.netlify.app/images/Kids/Babies/Kids%20a-10.jpg" alt="image" />
                                                            </Link>
                                                            <div className="curve_box">
                                                                <div className="p_icon">
                                                                    <a href=''>
                                                                        <FaRegHeart className='p-icon' />
                                                                    </a>
                                                                    <a href=''>
                                                                        <IoCartOutline className='p-icon' />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <a href="/single-product.html?name=${items[i].name}">
                                                            <h4>{product.name}</h4>
                                                        </a>
                                                        <div className="d-flex justify-content-center align-items-center mb-1">
                                                            <div className="small-ratings me-2">
                                                                <i className="fa fa-star rating-color"></i>
                                                                <i className="fa fa-star rating-color"></i>
                                                                <i className="fa fa-star rating-color"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                            </div>
                                                            <h4 className="review">1 review</h4>
                                                        </div>
                                                        <h5>${product.price}</h5>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    })
                                } */}
                            </div>
                        }

                    </div>
                </div>
            </section >
            {/* <!--================End Feature Product Area =================--> */}


            {/* <!--================Post Area =================--> */}
            <section className="post_area section_gap section_gap">
                <div className="container-fluid">
                    <div className="main_title">
                        <h2>Our Latest Posts</h2>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="card post_card">
                                <div className="post_image">
                                    <img src={post_1} alt="img" className="img-fluid" />
                                    <div className="post_date">
                                        <h4 className="date">02</h4>
                                        <p className="month">Apr</p>
                                    </div>
                                </div>
                                <div className="post_card_body content text-center">
                                    <ul className="d-flex justify-content-center">
                                        <li>Lifestyle</li>
                                        <li>VU Thin</li>
                                    </ul>
                                    <h4 className="post_title">Honor Earth Day Fourth Annual Ladybug Love Campaign</h4>
                                    <p className="post_desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore...</p>
                                    <button className="btn read_more_btn">Read More <i className="fa-solid fa-arrow-right ml-1"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="card post_card">
                                <div className="post_image">
                                    <img src={post_2} alt="img" className="img-fluid" />
                                    <div className="post_date">
                                        <h4 className="date">02</h4>
                                        <p className="month">Apr</p>
                                    </div>
                                </div>
                                <div className="post_card_body content text-center">
                                    <ul className="d-flex justify-content-center">
                                        <li>Lifestyle</li>
                                        <li>VU Thin</li>
                                    </ul>
                                    <h4 className="post_title">Honor Earth Day Fourth Annual Ladybug Love Campaign</h4>
                                    <p className="post_desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore...</p>
                                    <button className="btn read_more_btn">Read More <i className="fa-solid fa-arrow-right ml-1"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="card post_card">
                                <div className="post_image">
                                    <img src={post_3} alt="img" className="img-fluid" />
                                    <div className="post_date">
                                        <h4 className="date">02</h4>
                                        <p className="month">Apr</p>
                                    </div>
                                </div>
                                <div className="post_card_body content text-center">
                                    <ul className="d-flex jc-center">
                                        <li>Lifestyle</li>
                                        <li>VU Thin</li>
                                    </ul>
                                    <h4 className="post_title">Honor Earth Day Fourth Annual Ladybug Love Campaign</h4>
                                    <p className="post_desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore...</p>
                                    <button className="btn read_more_btn">Read More <i className="fa-solid fa-arrow-right ml-1"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================End Post Area =================--> */}


            {/* <!--================About Area =================--> */}
            {/* <section className="about-area section_gap" id='about'>
                <div className="overlay" id="about"></div>
                <div className="container-fluid">
                    <div className="main_box">
                        <div className="row d-flex justify-content-center align-items-center mx-0">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <img src={about} alt="image" className="img-fluid w-100" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="main_title about-text">
                                    <h2>About Us</h2>
                                    <p>
                                        Welcome to Fashiop, your ultimate destination for the latest and most captivating fashion
                                        footwear. At Fashiop, we're more than just a shoe retailer – we're a hub of creativity,
                                        self-expression, and trendsetting designs. Our passion lies in providing you with a curated
                                        selection of shoes that not only elevate your style but also tell your unique story. From
                                        elegant heels that exude sophistication to casual kicks that embrace comfort, our collection
                                        caters to diverse tastes and preferences. Our team of fashion enthusiasts scours the globe to
                                        bring you the hottest runway-inspired looks and classic essentials, ensuring that you're always
                                        steps ahead in the fashion game. With a commitment to quality craftsmanship and attention to
                                        detail, every pair of shoes we offer is a masterpiece of artistry and comfort. Whether you're
                                        stepping into a formal affair or embarking on a leisurely stroll, Fashiop has the perfect pair
                                        for every occasion. Join us in celebrating the boundless world of footwear fashion and let your
                                        shoes make a statement that resonates. Discover your style, one step at a time, with Fashiop.
                                    </p>
                                    <Link to="/shop" className="shop_btn">Go To Shop <i className="fa-solid fa-arrow-right ml-1"></i></Link>
                                </div>
                            </div>
                            <div className="row" id="">

                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--================End About Area =================--> */}


            {/* <!--================ Subscription Area ================--> */}
            {/* <Subscription /> */}
            {/* <!--================ End Subscription Area ================--> */}

            <Footer />
        </>
    )
}

export default Home
