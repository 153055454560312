import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Subscription from "../components/Subscription";
import Footer from "../components/Footer";
import { FaMinus, FaPlus, FaRegHeart } from "react-icons/fa";
import { IoCartOutline } from "react-icons/io5";

import PRODUCT from "../assets/images/NIKE AIR JORDAN MID/1/zzmfk3kwuw3ktkilxfnp.webp";
import Sizes from "../components/Sizes";
import Colors from "../components/Colors";
import Material from "../components/Material";
import Style from "../components/style";

import { items as relatedProducts } from "../js/items";
import { Link, useParams } from "react-router-dom";
import { addToCart, getAllVariation, getProductVariants, getSingleProduct, } from "../ContextAPI/APIs/api";
import { Alert } from "../ContextAPI/Components/notify";
import { File_URL } from "../config";
import { handleImageError } from "../helpers/helper";
import ProductCard from "../components/ProductCard";


function SingleProduct() {

  const { id } = useParams();

  const [pDesc, setPDesc] = useState("description");

  const [pImg, setPImg] = useState("");
  const [product, setProduct] = useState([]);
  const [variants, setVariants] = useState([]);

  const [FilteredProducts, setFilteredProducts] = useState([])
  const [counter, setcounter] = useState(0)


  const [allVariations, setAllVariations] = useState([])

  const [cartData, setCartData] = useState({
    qty: 1,
    size_id: null,
    color_id: null,
    material_id: null,
  });

  const filterSelectedVariantProducts = (variantName, variantID) => {
    let products = variants;

    const filteredProducts = variants.filter(products => products[variantName] == variantID)
    console.log(filteredProducts, "==============");


    if (products.length > 0) {
      products.forEach((element, variantIndex) => {
        filteredProducts.forEach(variant => {
          if (element.size_id != variant.size_id) {
            products[variantIndex].sizeDisabled = true;
          } else {
            products[variantIndex].sizeDisabled = false;
          }
        })
      });
    }



    // setFilteredProducts(filteredProducts)
    // setcounter(counter + 1)
    console.log(products, '================PRODUCTS FILTEREDSHAUDASH')
    setVariants(products)
  }

  const handleClickVariant = (VariantId, variantType) => {

    if (variantType == 'color') {
      filterSelectedVariantProducts('color_id', VariantId)
    }
    else if (variantType == 'size') {
      filterSelectedVariantProducts('size_id', VariantId)
    }
    else if (variantType == 'material') {
      filterSelectedVariantProducts('material_id', VariantId)
    }


    setCartData((prevState) => {
      console.log("prev", prevState); // Logging previous state

      // Determine which property to update based on the variantType
      let updatedProperty;
      if (variantType === "size") {
        updatedProperty = "size_id";
      } else if (variantType === "color") {
        updatedProperty = "color_id";
      } else if (variantType === "material") {
        updatedProperty = "material_id";
      }

      return {
        ...prevState,
        [updatedProperty]: VariantId,
      };
    });

    console.log(variantType, "Id:", VariantId);
  };

  const GetSingleProduct = async () => {
    try {
      const response = await getSingleProduct(id);
      if (response.success) {
        setProduct([{ ...response.product[0], image_urls: response.product[0].image_url !== null ? (response.product[0].image_url.split(",")) : [] }]);
        setPImg(response.product[0].image_url !== null ? (response.product[0].image_url.split(","))[0] : "");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getProductVariantsData = async () => {
    try {
      const response = await getProductVariants(id);
      console.log("iddddddddddddddddddddddddddddddd",id)
      if (response.success) {
        setVariants(response?.productConfig);
      }
    } catch (error) {
      console.log("Error fetching product variants:", error);
    }
  };

  // function for adding inn cart
  const addItemInCart = async () => {
    try {
      const match = variants.find(e => {
        return e.color == cartData.color_id && e.size == cartData.size_id && e.material == cartData.material_id
      })

      if (match) {
        const body = {
          qty: cartData.qty,
          productVariantId: match.product_config_id
        };
        const response = await addToCart(id, body);
        if (response.success) {
          Alert(response.message, response.success);
        } else {
          Alert("Failed to add item to cart");
        }
      } 
      
      else {
        // If no match found, pass only qty in the body
        const body = {
          qty: cartData.qty
        };
        const response = await addToCart(id, body);
        if (response.success) {
          Alert("Item added to cart without variant");
        } else {
          Alert("Failed to add item to cart");
        }
      }
      
      
    } catch (error) {
      console.log("error in ading to cart", error);
    }
  };

  // get al variations 
  const getVariations = async () => {
    try {
      const response = await getAllVariation()
      if (response.success) return setAllVariations(response.varaition)
    } catch (error) {
      console.log(error)
      Alert(error.message)
    }
  }

  // Quantity counter
  function increament() {
    setCartData({ ...cartData, qty: cartData["qty"] + 1 });
  }
  function decreament() {
    if (cartData.qty > 1) {
      setCartData({ ...cartData, qty: cartData["qty"] - 1 });
    }
  }

  useEffect(() => {
    GetSingleProduct();
    getProductVariantsData();

  }, []);




  const [selectedColor, setselectedColor] = useState("");
  const [selectedSize, setselectedSize] = useState("");
  const [selectedMaterial, setselectedMaterial] = useState("");



  return (
    <>
      <Navbar />


      {/* <!--================Home Banner Area =================--> */}
      <Banner BannerHeading="Product Details" PageRoute="/single-product" PageLink="Single Product" />
      {/* <!--================End Home Banner Area =================--> */}


      {/* <!--================Single Product Area =================--> */}
      <div className="product_image_area">
        <div className="container">
          <div className="row s_product_inner" id="myProduct">
            <div className="col-lg-6">
              <div className="s_product_img">
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" >
                  <ol className="carousel-indicators" id="innerImages">
                    {product.length > 0 &&
                      product[0].images.map((img_url, i) => {
                        return <img key={i} src={`${File_URL}/${img_url}`} onError={handleImageError} onClick={(e) => setPImg(img_url)} alt="image" className="img-fluid" style={{ height: "70px", cursor: "pointer" }} />
                      })}
                      
                      {console.log("File_URL product[0]?.image_url", `${File_URL}/${product[0]?.image_url}`)}
                      
                    {/* <img src={`${File_URL}/${product[0]?.image_url}`} onError={handleImageError} alt="image" className="img-fluid" /> */}


                  </ol>
                  <div className="carousel-inner" id="outerImages">
                    <img src={`${File_URL}/${pImg}`} onError={handleImageError} alt="image" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="s_product_text">
                <h3>{product[0]?.name}</h3>
                <h2>${product[0]?.price}</h2>
                <ul className="list">
                  <li>
                    <a className="active" href="#"><span>Category </span> {product[0]?.category_name} </a>
                  </li>
                  <li>
                    {product[0]?.stock_management ===1 ? <a href="#"> <span>Availibility :</span> {product[0].total_quantity} items are available in stock  </a> :<> </>}
                    
                  </li>
                </ul>
                <p>{/* {product?.description} */}</p>

                <Colors Variants={variants.map(e => { return { color: e.color, size: e.size, material: e.material, stock_quantity: e.stock_quantity } })} onColorClick={handleClickVariant} cartData={cartData} />

                <Sizes Variants={variants.map(e => { return { color: e.color, size: e.size, material: e.material, stock_quantity: e.stock_quantity } })} onSizeClick={handleClickVariant} cartData={cartData} />

                <Material Variants={variants.map(e => { return { color: e.color, size: e.size, material: e.material, stock_quantity: e.stock_quantity } })} onMaterialClick={handleClickVariant} cartData={cartData} />

                {/* <Style Variants={variants} /> */}

                <div className="s_product_quantity d-flex">
                  {/* <h5>Quantity:{product[0]?.quantity}</h5> */}
                  <div className="qty_btn d-flex ai-center me-2">
                    <button onClick={() => decreament()} className="sub qButton sub_btn">
                      <FaMinus />
                    </button>
                    <input type="text" value={cartData.qty} readOnly className="quantity-text" />
                    <button onClick={() => increament()} className="add qButton-positive add_btn">
                      <FaPlus />
                    </button>
                  </div>

                  <div className="card_area">
                    <button onClick={() => addItemInCart()} className="main_btn add-to-cart-button">Add to Cart</button>
                    {/* <a className="icon_btn" >
                      <FaRegHeart />
                    </a> */}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--================End Single Product Area =================--> */}


      {/* <!--================Product Description Area =================--> */}
      <section className="product_description_area">
        <div className="container">
          {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a className="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                aria-selected="true">Description</a>
            </li>
            <li className="nav-item">
              <a className="nav-link active" id="review-tab" data-toggle="tab" href="#review" role="tab"
                aria-controls="review" aria-selected="false">Rating</a>
            </li>
          </ul> */}


          <div className="product_description_tabs d-flex jc-center mb-3">
            <div className={`${pDesc == "description" ? "desc_active" : "desc_unactive"}`} onClick={() => setPDesc("description")}>
              <h5>Description</h5>
            </div>
            <div className={`${pDesc == "customTabs" ? "desc_active" : "desc_unactive"} mx-4`} onClick={() => setPDesc("customTabs")}>
              <h5>Custom Tab</h5>
            </div>
            <div className={`${pDesc == "reviews" ? "desc_active" : "desc_unactive"}`} onClick={() => setPDesc("reviews")}>
              <h5>Reviews</h5>
            </div>
          </div>



          <div className="row tab-content">
            {
              pDesc == "description" &&
              <>
                <div className="col-md-12">
                  <div className="tab-pane fade show">
                    <p>
                      Beryl Cook is one of Britain’s most talented and amusing
                      artists .Beryl’s pictures feature women of all shapes and
                      sizes enjoying themselves .Born between the two world wars,
                      Beryl Cook eventually left Kendrick School in Reading at the
                      age of 15, where she went to secretarial school and then into
                      an insurance office. After moving to London and then Hampton,
                      she eventually married her next door neighbour from Reading,
                      John Cook. He was an officer in the Merchant Navy and after he
                      left the sea in 1956, they bought a pub for a year before John
                      took a job in Southern Rhodesia with a motor company. Beryl
                      bought their young son a box of watercolours, and when showing
                      him how to use it, she decided that she herself quite enjoyed
                      painting. John subsequently bought her a child’s painting set
                      for her birthday and it was with this that she produced her
                      first significant work, a half-length portrait of a
                      dark-skinned lady with a vacant expression and large drooping
                      breasts. It was aptly named ‘Hangover’ by Beryl’s husband and
                    </p>
                  </div>
                </div>
              </>
            }

            {
              pDesc == "customTabs" &&
              <div className="col-12">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium dolore voluptate nobis eum laudantium. Reprehenderit quisquam quod quaerat commodi, minus eos nihil totam voluptatum ipsum id molestiae sint, hic eligendi!
                  Sint, debitis nemo. Rem dolorem id vero eum voluptate? Dolore, nobis amet. Reiciendis voluptatem debitis assumenda facere omnis error animi veritatis, numquam quia at harum ipsum tempora nostrum similique saepe?
                  Ducimus dicta, officiis tempora iure a fuga eius et voluptatem. Dolorem nostrum nisi suscipit. Ipsum officiis odit voluptatibus accusantium nam. Ipsum assumenda eligendi placeat beatae maxime in tempore ad voluptas.
                  Eaque deserunt iusto amet vero consequatur rerum adipisci mollitia facilis, dolorem autem et doloremque laboriosam iure eius eligendi? Id recusandae nam blanditiis nemo excepturi quis tenetur atque maxime nisi in.
                  Ipsam, obcaecati. A hic accusantium vero atque esse eaque totam sunt excepturi deserunt eligendi assumenda quibusdam ipsum ullam facilis similique mollitia qui, obcaecati aliquid tempora rerum vel, quia sapiente. Nihil.
                </p>
              </div>
            }

            {
              pDesc == "reviews" &&
              <div className="col-md-12">
                <div className="tab-pane fade show active ratings_card">
                  <div className="row">

                    <div className="col-lg-8">
                      <div className="review_box">
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae cumque totam nisi culpa nostrum. Consequatur, error. Doloremque consectetur quaerat laboriosam explicabo, a error voluptatem amet inventore voluptas, quos harum quis.</p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae cumque totam nisi culpa nostrum. Consequatur, error. Doloremque consectetur quaerat laboriosam explicabo, a error voluptatem amet inventore voluptas, quos harum quis.</p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae cumque totam nisi culpa nostrum. Consequatur, error. Doloremque consectetur quaerat laboriosam explicabo, a error voluptatem amet inventore voluptas, quos harum quis.</p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae cumque totam nisi culpa nostrum. Consequatur, error. Doloremque consectetur quaerat laboriosam explicabo, a error voluptatem amet inventore voluptas, quos harum quis.</p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae cumque totam nisi culpa nostrum. Consequatur, error. Doloremque consectetur quaerat laboriosam explicabo, a error voluptatem amet inventore voluptas, quos harum quis.</p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae cumque totam nisi culpa nostrum. Consequatur, error. Doloremque consectetur quaerat laboriosam explicabo, a error voluptatem amet inventore voluptas, quos harum quis.</p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae cumque totam nisi culpa nostrum. Consequatur, error. Doloremque consectetur quaerat laboriosam explicabo, a error voluptatem amet inventore voluptas, quos harum quis.</p>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="total_rate d-flex ai-center">
                        <div className="box_total">
                          <h5>Overall</h5>
                          <h4>4.0</h4>
                          <h6>(03 Reviews)</h6>
                        </div>
                        <div className="rating_list">
                          <h3>Based on 3 Reviews</h3>
                          <ul className="list">
                            <li>
                              <a href="#">
                                5 Star
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i> 01
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                4 Star
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i> 01
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                3 Star
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i> 01
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                2 Star
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i> 01
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                1 Star
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i> 01
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

          </div>
        </div>
      </section>
      {/* <!--================End Product Description Area =================--> */}


      {/* <!--================Related Product Area =================--> */}
      <section className="related_product_area">
        <div className="container">
          <h1>Related Products</h1>

          <div className="row">

            <ProductCard />

            {/* {relatedProducts.map((product, i) => {
              if (i <= 3) {
                return (
                  <>
                    <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                      <div className="f_p_item">
                        <div className="f_p_img">
                          <Link to="/single-product">
                            <img
                              className="img-fluid"
                              src={
                                "https://cdn.shopify.com/s/files/1/1326/1029/files/T4SM9589RT_BLACK_0402_grande.jpg?v=1699309007"
                              }
                              alt="image"
                            />
                          </Link>
                          <div className="curve_box">
                            <div className="p_icon">
                              <a href=''>
                                <FaRegHeart className='p-icon' />
                              </a>
                              <a href=''>
                                <IoCartOutline className='p-icon' />
                              </a>
                            </div>
                          </div>
                        </div>
                        <a href="/single-product.html?name=${items[i].name}">
                          <h4>{product.name}</h4>
                        </a>
                        <div className="d-flex justify-content-center align-items-center mb-1">
                          <div className="small-ratings me-2">
                            <i className="fa fa-star rating-color"></i>
                            <i className="fa fa-star rating-color"></i>
                            <i className="fa fa-star rating-color"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                          </div>
                          <h4 className="review">1 review</h4>
                        </div>
                        <h5>${product.price}</h5>
                      </div>
                    </div>
                  </>
                );
              }
            })} */}
          </div>
        </div>
      </section>
      {/* <!--================End Related Product Area =================--> */}


      {/* <!--================ Subscription Area ================--> */}
      <Subscription />
      {/* <!--================ End Subscription Area ================--> */}


      <Footer />
    </>
  );
}

export default SingleProduct;
