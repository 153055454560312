import React, { useEffect, useState } from "react";
import { getOptionsByIds } from "../ContextAPI/APIs/api";



function Colors({ Variants, onColorClick, cartData }) {

  if (Variants.length == 0) return <></>;

  return (
    <>
      <div className="s_product_colors">
        <h5>Color</h5>
        <div className="d-flex flex-wrap gap-2">
          {Array.from(new Map(Variants.map(item => [item.color, item])).values()).map((color, i) => {
            return (
              <button key={i} disabled={color.stock_quantity == 0} className={`btn ${color.stock_quantity <= 0 ? "color_unavailable" : cartData.color_id == color.color ? "selected-color-circle" : "color_unavailable"}`} onClick={() => { onColorClick(color.color, 'color'); console.log((color)) }} style={{ backgroundColor: `${color.color}` }} ></button>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Colors;