import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Subscription from "../components/Subscription";
import Footer from "../components/Footer";
import { FaMinus, FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { Alert } from "../ContextAPI/Components/notify";
import { DeleteCartItem, addToCart, getCarts } from "../ContextAPI/APIs/api";
import { Button } from 'react-bootstrap';
import { File_URL } from "../config";
import { handleImageError } from "../helpers/helper";
import { HiArrowSmLeft, HiArrowSmRight } from "react-icons/hi";
// import {  useParams } from "react-router-dom";


import PRODUCT from "../assets/images/NIKE AIR JORDAN MID/1/zzmfk3kwuw3ktkilxfnp.webp";


function Cart() {
  // const { id } = useParams();

  const [carts, setCarts] = useState([]);
  const [totalOrderAmount, setTotalOrderAmount] = useState(0);


  const [qty, setQty] = useState("");

  // Quantity counter
  const [count, setCount] = useState(1);


  // add  item into cart
  const updateQty = async (productId, productVariantId, qty, index) => {
    try {
      console.log(">><><><><>");
      const body = {
        productId: productId,
        productVariantId: productVariantId || null,
        qty: qty
      }
      const response = await addToCart(productId, body);
      console.log("eeeeeeeeeee", response);
      console.log('bodyyyyy', body)



      if (response.success) {

        let cart = carts;
        // alert(index)
        cart[index].quantity_of_product += qty;

        setCarts(cart);
        setCount(count + qty);

      }
      return Alert(response.message, response.success);



    } catch (error) {
      console.log("error in ading to cart", error);
    }
  };


  // delete cart item 


  const deleteItemFromCart = async (cartId) => {
    try {
      const response = await DeleteCartItem({ id: cartId });
      if (response.success) {
        Alert(response.message, response.success);

        return getUserCartsData()
      }

    } catch (error) {
      console.log(error);
    }
  };

  const getUserCartsData = async () => {
    try {
      console.log("arha h ");
      const resposne = await getCarts();
      if (resposne.success) {
        setCarts(resposne.carts);
        setTotalOrderAmount(resposne.totalOrderAmount);
      }
    } catch (error) {
      console.log(error);
      Alert(error.message);
    }
  };


  useEffect(() => {
    getUserCartsData();
  }, []);


  return (
    <>
      <Navbar />

      {/* <!--================Home Banner Area =================--> */}
      <Banner BannerHeading="Shopping Cart" PageRoute="/cart" PageLink="Cart" />
      {/* <!--================End Home Banner Area =================--> */}

      {/* <!--================Cart Area =================--> */}
      <section className="cart_area">
        <div className="container">
          <div className="cart_inner">


            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Price</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Total</th>
                    <th scope="col">Action</th>

                  </tr>
                </thead>
                <tbody id="cartProductDiv">


                  {carts.map((cart, i) => (
                    <tr key={i}>
                      <td>
                        <div className="media">
                          <div className="d-flex">
                            {/* <img
                              src={cart.product_image_url}
                              height="150px"
                              alt={cart.product_name}
                            /> */}
                            <img className="img-fluid cart_image" src={`${File_URL}/${cart.image_url}`} alt="image" onError={handleImageError} />
                          </div>
                          <div className="media-body">
                            <h4>{cart.product_name}</h4>
                            <p>{cart.product_description}</p>
                            {cart.size !== null ? <p><strong>Size:</strong>  {cart.size}</p> : <></>}
                            {cart.material !== null ? <p><strong>Material:</strong> {cart.material}</p> : <></>}
                            {cart.color !== null ? <p><strong>Color:</strong>  {cart.color}</p> : <></>}

                           

                          </div>
                        </div>
                      </td>
                      <td>
                        <h5>{cart.unit_price}</h5>
                      </td>
                      <td>
                        <div className="d-flex">
                          <button onClick={() => updateQty(cart.product_id, cart.product_config_id, (-1), i)} className="sub qButton outline_btn" onChange={(e) => setQty(e.target.value)}    >
                            <FaMinus />
                          </button>
                          <input type="text" value={cart?.quantity_of_product} readOnly className="quantity-text" />
                          <button onClick={() => updateQty(cart.product_id, cart.product_config_id, 1, i)} className="add qButton-positive outline_btn" onChange={(e) => setQty(e.target.value)}  >
                            <FaPlus />
                          </button>
                        </div>
                      </td>
                      <td>
                        <h5 id={`total${cart.id}`}>{cart.total_price}</h5>
                      </td>
                      <td>
                        <button style={{ border: "none", background: "transparent", color: "var(--danger)" }} onClick={() => deleteItemFromCart(cart.cart_item_id)}> <FaRegTrashAlt /> </button>
                        {/* <h5 id={`total${cart.id}`}></h5> */}
                      </td>

                    </tr>
                  ))}


                  {/* <tr>
                    <td>
                      <div className="media d-flex ai-center">
                        <div className="d-flex">
                          <img className="img-fluid cart_image" src={PRODUCT} alt="image" />
                        </div>
                        <div className="media-body">
                          <h4>{"NIKE AIR JORDAN MID"}</h4>
                          <p>{"cart.product_description"}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <h5>Rs {"48,200"}</h5>
                    </td>
                    <td>
                      <div className="d-flex">
                        <button className="sub qButton outline_btn" >
                          <FaMinus />
                        </button>
                        <input type="text" value={"1"} readOnly className="quantity-text" />
                        <button className="add qButton-positive outline_btn" >
                          <FaPlus />
                        </button>
                      </div>
                    </td>
                    <td>
                      <h5>Rs {"48,200"}</h5>
                    </td>
                    <td>
                      <button style={{ border: "none", background: "transparent", color: "var(--danger)" }}> <FaRegTrashAlt /> </button>
                    </td>

                  </tr> */}



                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>

                    <td>
                      <h5>Subtotal</h5>
                    </td>
                    <td>
                      <h5 id="subtotal">{totalOrderAmount}</h5>
                    </td>
                  </tr>

                  {/* <tr className="out_button_area">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr> */}

                </tbody>
              </table>
            </div>


            <div className="checkout_btn_inner mt-4 d-flex jc-between flex-wrap">
              <Link to="/shop" className="gray_btn mr-3 d-flex ai-center mb-3">
                <HiArrowSmLeft className="arrow_icon me-2" />
                Continue Shopping
              </Link>
              <Link to="/checkout" style={{ pointerEvents: `${carts.length > 0 ? "auto" : "none"}` }} className="main_btn add-to-cart-button d-flex ai-center dark_hover_btn mb-3" >
                Proceed to checkout
                <HiArrowSmRight className="arrow_icon ml-2" />
              </Link>
            </div>


          </div>
        </div>
      </section>
      {/* <!--================End Cart Area =================--> */}

      {/* <!--================ Subscription Area ================--> */}
      <Subscription />
      {/* <!--================ End Subscription Area ================--> */}

      <Footer />
    </>
  );
}

export default Cart;
