import React, { useEffect, useState } from 'react'
import AdminSidebar from '../../components/AdminSidebar';
import { addProductImage, addProductVariant, deleteProductImage, getAllCategories, getAllVariants, getAllVariationBasisOnVariant, getProduct, getSingleProduct, updateProduct } from '../../ContextAPI/APIs/api';

import { useParams } from 'react-router-dom';
import { Alert } from '../../ContextAPI/Components/notify';
import { useAuth } from '../../ContextAPI/Components/auth';
import { File_URL } from '../../config';
import { handleImageError } from '../../helpers/helper';
import { Gallery } from "react-grid-gallery";
import GalleryImages from '../GalleryImages';
import { Modal } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ProductData = () => {
    const [active, setActive] = useState("edit")
    const [categories, setCategories] = useState([])
    const { formData, setFormData } = useAuth();

    const { id } = useParams()


    const getCategories = async () => {
        console.log(">>>>>>>>>>>>>>>>>>>>");
        try {
            const response = await getAllCategories({ type: "simple" });
            console.log(response);
            if (response.success) setCategories(response.categories);
        } catch (error) {
            console.log("errro", error);
            Alert(error.message, "error");
        }
    };



     // get single product 
     const GetSingleProduct = async () => {
        try {
            const response = await getSingleProduct(id);
            if (response.success) {
                console.log("response.product[0]",response.product[0]);
                
                setFormData(response.product[0]);
                // setPImg(response.product[0].image_url !== null ? (response.product[0].image_url.split(","))[0] : "");
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        GetSingleProduct()
        getCategories();

    }, [])

    return <>


        <section className="admin_pages edit_product_area">
            <div className="container">

                <div className="card">
                    <div className='d-flex jc-center mb-4'>
                        <button className={`${active == "edit" ? "tab_btn_active" : "tab_btn_unactive"} mx-4`} onClick={() => setActive("edit")}>Product</button>
                        <button className={`${active == "img" ? "tab_btn_active" : "tab_btn_unactive"} mx-4`} onClick={() => setActive("img")}>Images</button>
                        <button className={`${active == "variant" ? "tab_btn_active" : "tab_btn_unactive"} mx-4`} onClick={() => setActive("variant")}>Variants</button>
                    </div>

                    <div>
                        {
                            active == "edit" ?
                                <EditProductData categories={categories} setCategories={setCategories} getSingleProduct={GetSingleProduct} />
                                :
                                active == "img" ?
                                    <ProductImages />
                                    :
                                    <ProductVariants categories={categories} setCategories={setCategories} getSingleProduct={GetSingleProduct}/>

                        }
                    </div>
                </div>

            </div>
        </section>
    </>
}




const EditProductData = ({categories, setCategories,getSingleProduct}) => {
    const { formData, setFormData } = useAuth();
    const { id } = useParams()
    const [product, setProduct] = useState([])
    const [pImg, setPImg] = useState("");

console.log("formData.status",formData);
    // const [formData, setFormData] = useState({
    //     name: "",
    //     short_description: "",
    //     long_description: "",
    //     price: "",
    //     stock_management: "",
    //     total_quantity: "",
    //     SKU: "",
    //     category_id: "",
    // });


   

    //   update product 
    const productUpdate = async (e) => {
        e.preventDefault()
        try {
            const response = await updateProduct(id, formData)
            if (response.success) Alert(response.message, response.success)
        } catch (error) {
            console.log(error)
        }
    }


    


    return (
        <>
            <h3>Edit Product</h3>
            <form className="login_form" id="contactForm" onSubmit={(e) => { productUpdate(e) }} >
                <div className="form-group">
                    <input type="text" className="form-control" id="name" name="name" placeholder="Product Name" value={formData?.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} required />
                </div>
                <div className="form-group">
                    <input type="text" className="form-control" id="description" name="short_description" placeholder="Product Description" value={formData?.short_description} onChange={(e) => setFormData({ ...formData, short_description: e.target.value })} required />
                </div>
                <div className="form-group">

                <CKEditor
                                                editor={ClassicEditor}
                                                data={formData?.long_description || ""}
                                                onReady={editor => {
                                                    // Add a class name to the CKEditor content element

                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        long_description: data,
                                                    }));
                                                    // setEditorData(data); // Update editorData state
                                                    // console.log({ event, editor, data });
                                                }}
                                                onBlur={(event, editor) => {
                                                    // console.log("Blur.", editor);
                                                }}
                                                onFocus={(event, editor) => {
                                                    // console.log('Focus', editor);
                                                }}
                                            />
                    {/* <input type="text" className="form-control" id="description" name="long_description" placeholder="Product detailed Description" value={formData?.long_description} onChange={(e) => setFormData({ ...formData, long_description: e.target.value })} required /> */}
                </div>
                <div className="form-group">
                    <input type="number" className="form-control" id="price" name="price" placeholder="Price" value={formData?.price} onChange={(e) => setFormData({ ...formData, price: e.target.value })} required />
                </div>
                <div className="form-group">
                    <select className="form-select" value={formData?.stock_management} onChange={(e) => setFormData({ ...formData, stock_management: e.target.value, })} >
                        <option value="">Choose Stock Management</option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                    </select>
                </div>
                {
                    formData?.stock_management == 1 &&
                    <div className="form-group">
                        <input type="number" className="form-control" id="total_quantity" name="total_quantity" placeholder="Total Quantity" value={formData?.total_quantity} onChange={(e) => setFormData({ ...formData, total_quantity: e.target.value, })} required />
                    </div>
                }
                <div className="form-group">
                    <input type="text" className="form-control" id="SKU" name="SKU" placeholder="SKU" value={formData.SKU} onChange={(e) => setFormData({ ...formData, SKU: e.target.value })} required />
                </div>
                <div className="form-group">
                    <select className="form-select" value={formData?.category_id} onChange={(e) => setFormData({ ...formData, category_id: e.target.value })}>
                        <option value="">Choose Category</option>
                        {categories.map((c) => (
                            <option key={c.id} value={c.id} selected={formData?.category_id == c.id} >   {c.category_name} </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
    <select className="form-select" value={formData.status} onChange={(e) => setFormData({ ...formData, status: e.target.value })}>
        <option value="">Choose Status</option>
        <option value={"active"}>Active</option>
        <option value={"inactive"}>InActive</option>

    </select>
</div>

                <div className="mb-0">
                    <button type="submit" value="submit" className="submit_btn">Update</button>
                </div>
            </form>
        </>
    )
}


const ProductImages = () => {

    const { formData, setFormData } = useAuth();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { id } = useParams()


    const GetSingleProduct = async () => {
        try {
            const response = await getSingleProduct(id);
            if (response.success) {
                setFormData(response.product[0]);
                // setPImg(response.product[0].image_url !== null ? (response.product[0].image_url.split(","))[0] : "");
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const productImageDelete = async (imgName) => {
        try {
            const response = await deleteProductImage({ image_url: imgName })
            if (response.success) return Alert(response.message, response.success)
        } catch (error) {

        }
    }

    useEffect(() => {
        GetSingleProduct()
    }, []);


    return <>

        <h3>Product Images</h3>
        <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
        {formData.images[0] !== null ? (
        formData.images.map((img_url, i) => (
            <div key={i} style={{ height: "100px", width: "100px" }}>
                <img src={`${File_URL}/${img_url}`} onError={handleImageError} alt="image" className="h-100 w-100" />
                <button className='btn-danger' onClick={() => productImageDelete(img_url)}>Delete image</button>
            </div>
        ))
    ) : (
        <> <h3>NO images</h3></>
    )}
        </div>
        <div className="mb-0">
            <button className='submit_btn' onClick={handleShow}>Add Images</button>
        </div>


        <Modal show={show} onHide={handleClose} centered>

            <Modal.Body>
                <GalleryImages />
            </Modal.Body>

        </Modal>
    </>
}







//  diffrent component for adding variant 
const ProductVariants = ({categories, setCategories}) => {
    const { id, category } = useParams();

    const [products, setProducts] = useState([]);
    const [variations, setVariations] = useState([]);
    const [variationOption, setVariationOption] = useState({
        color: [],
        size: [],
        material: [],
    });
    const [initialData, setInitialData] = useState(
        {
            product_id: id,
            category_id: category,
            size_id: null,
            color_id: null,
            material_id: null,
            stock_quantity: "",
            price: "",
        },
    );

    const [formData, setFormData] = useState([]);
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await addProductVariant(formData);
          
            if (response.success) {
                console.log("Success:", response);
                Alert(response.message, response.success);
            } else {
                console.log("Failure:", response);
                Alert(response.message, response.success);
            }
        } catch (error) {
            console.log("Error:", error);
            Alert(error.message,false);
            setFormData([]); // Clear formData when response is unsuccessful
        }
    };
    
    //   get all products
    const getAllProducts = async () => {
        try {
            const response = await getProduct();
            console.log("response", response);
            if (response.success) setProducts(response?.product);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getAllVariations = async () => {
        try {
            const response = await getAllVariants();
            if (response.success) setVariations(response?.varaition);
        } catch (error) {
            console.log(error);
            Alert(error.message);
        }
    };

    const getVariationBasisOnVariant = async (fieldName) => {
        try {
            const response = await getAllVariationBasisOnVariant(fieldName);
            if (response.success)
                setVariationOption((prevState) => ({
                    ...prevState,
                    [fieldName]: response.variantOption,
                }));
        } catch (error) {
            console.log(error.message)
            Alert(error.message);
        }
    };

    const pushInitialData = async () => {
        // console.log(
        //   !initialData.size && !initialData.material && !initialData.color
        // );
        if (!initialData.stock_quantity || !initialData.price) {
            return Alert("Please Add Price and Quantitiy");
        } else if (
            !initialData.size_id &&
            !initialData.color_id &&
            !initialData.material_id
        ) {
            return Alert("Please Select One Variant");
        }

        setFormData([...formData, initialData]);
        console.log([...formData, initialData]);
    };

    const AddAnotherVariant = () => {
        setInitialData({
            product_id: id,
            category_id: category,
            size_id: null,
            color_id: null,
            material_id: null,
            stock_quantity: "",
            price: "",
        });
    };
 

    const [singleProduct, setSinglePorduct] = useState({
        name: "",
        short_description: "",
        long_description: "",
        price: "",
        stock_management: "",
        total_quantity: "",
        SKU: "",
        category_id: "",
    });

  // get single product 
  const GetSingleProduct = async () => {
    try {
        const response = await getSingleProduct(id);
        if (response.success) {
            setSinglePorduct(response.product[0]);
            // setPImg(response.product[0].image_url !== null ? (response.product[0].image_url.split(","))[0] : "");
        }
    } catch (error) {
        console.log("error", error);
    }
};

    useEffect(() => {
        getAllProducts();
        getAllVariations();
        GetSingleProduct()
        // getVariationBasisOnVariant();
    }, []);

    useEffect(() => {
        getVariationBasisOnVariant("size");
        getVariationBasisOnVariant("color");
        getVariationBasisOnVariant("material");
    }, []);


    return (
        <>

            <h3>Add Product Variant</h3>
                  
            <form className="login_form" id="contactForm" onSubmit={(e) => handleSubmit(e)} >
                <div className="form-group">
                    <select
                        className="form-select"
                        value={initialData.product_id}
                        onChange={(e) => setInitialData({ ...initialData, product_id: e.target.value, })} disabled>
                        <option value="">Select Product</option>
                        {products.map((p) => (
                            <option key={p.id} value={p.id}>
                                {p.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                
                    <select
                        className="form-select"
                        value={singleProduct?.category_id}
                        disabled
                        // onChange={(e) => setInitialData({ ...initialData, category_id: e.target.value, })}
                         >
                        <option value="">Select Category</option>
                        {categories.map((c) => (
                            <option key={c.id} value={c.id}>
                                {c.category_name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <select
                        className="form-select"
                        value={initialData.size_id}
                        onChange={(e) => setInitialData({ ...initialData, size_id: e.target.value, })} >
                        <option value="size">Select Size</option>
                        {variationOption.size.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <select
                        className="form-select"
                        value={initialData.material_id}
                        onChange={(e) => setInitialData({ ...initialData, material_id: e.target.value, })} >
                        <option value="size">Select Material</option>
                        {variationOption.material.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div>


                <div className="form-group">
                    <select
                        className="form-select"
                        value={initialData.color_id}
                        onChange={(e) => setInitialData({ ...initialData, color_id: e.target.value, })} >
                        <option value="">Select Color</option>
                        {variationOption.color.map((c) => (
                            <option key={c.id} value={c.id}>
                                {c.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        id="stock-quantiy"
                        name="name"
                        placeholder="Stock Quantity"
                        value={initialData.stock_quantity}
                        onChange={(e) => setInitialData({ ...initialData, stock_quantity: e.target.value, })}
                        required
                    />
                </div>

                <div className="form-group">
                    <input
                        type="number"
                        className="form-control"
                        id="price"
                        name="price"
                        placeholder="Price"
                        value={initialData.price}
                        onChange={(e) => setInitialData({ ...initialData, price: e.target.value })}
                        required
                    />
                </div>

                <div className="mb-0">
                    <button onClick={pushInitialData} type="button" className="btn submit_btn" >
                        Add Variant
                    </button>
                </div>

                {formData.map((e, i) => {
                    return (
                        <div>
                            {e?.size_id}, {e?.color_id}, {e?.material_id}, {e?.price},{" "}
                            {e?.stock_quantity}
                        </div>
                    );
                })}

                {formData.length > 0 && (
                    <>
                        <div className="mb-4">
                            <button type="button" onClick={AddAnotherVariant} className="submit_btn" >
                                ADD ANOTHER VARIANT
                            </button>
                        </div>

                        <div className="mb-4">
                            <button type="submit" className="submit_btn">
                                SAVE VARIANTS
                            </button>
                        </div>
                    </>
                )}

            </form>

        </>
    );
}



export default ProductData