import React, { useState } from 'react'
import { Alert } from '../ContextAPI/Components/notify'
import { addAddress } from '../ContextAPI/APIs/api'

const Addaddress = () => {

    const [addres, setAddres] = useState({
        street_number: "",
        address_line1: "",
        city: "",
        postal_code: ""
    })


    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await addAddress(addres)
            if (response.success) return Alert(response.message, response.success);
        } catch (error) {
            console.log(error)
            Alert(error.message)
        }
    }


    return (
        <>
            <section className="address_moadal_area">
                <div className="">
                    <h3>Add Address</h3>
                    <form className="login_form" onSubmit={(e) => handleSubmit(e)} id="contactForm">
                        <div className="form-group">
                            <input type="text" className="form-control" id="name" name="name" placeholder="Street Number"
                                value={addres.street_number} onChange={(e) => setAddres({ ...addres, street_number: e.target.value })} required />
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Address Line"
                                value={addres.address_line1} onChange={(e) => setAddres({ ...addres, address_line1: e.target.value })} required />
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="City"
                                value={addres.city} onChange={(e) => setAddres({ ...addres, city: e.target.value })} required />
                        </div>
                        <div className="form-group">
                            <input type="number" className="form-control" id="password" name="password" placeholder="Postal Code"
                                value={addres.postal_code} onChange={(e) => setAddres({ ...addres, postal_code: e.target.value })} required />
                        </div>

                        <div className="mb-0">
                            <button type="submit" value="submit" className="submit_btn">Add</button>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default Addaddress
