import React, { useState } from 'react'
import AdminSidebar from '../components/AdminSidebar'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { FaRegHeart } from 'react-icons/fa';
import { IoCartOutline } from 'react-icons/io5';

import PRODUCT from "../assets/images/NIKE AIR JORDAN MID/1/zzmfk3kwuw3ktkilxfnp.webp";
import { Button, Modal } from 'react-bootstrap';

function UI_Kit() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    return (
        <>
            <div className="d-flex">
                <div className="sidebar_div">
                    <AdminSidebar />
                </div>
                <div className="page_div">
                    <section className="admin_pages">

                        <div className='mb-5'>
                            <h2>Buttons</h2>

                            <div className="">
                                <button className='square_btn me-2'>Square</button>
                                <button className='outline_btn me-2'>Outline</button>
                                <button className='rounded_btn me-2'>Rounded</button>
                                <button className='dark_hover_btn me-2'>Dark Hover</button>
                                <button className='outline_hover_btn me-2'>Outline Hover</button>
                                <button className='fill_hover_btn'>Fill Hover</button>
                            </div>
                        </div>


                        <div className='mb-5'>
                            <h2>Modal</h2>

                            <button className="rounded_btn" onClick={handleShow}>
                                Launch Modal
                            </button>

                            <Modal show={show} onHide={handleClose} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>Modal heading</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
                                <Modal.Footer>
                                    <button className='square_btn' onClick={handleClose}>
                                        Save Changes
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        </div>

                        <div className='mb-5'>
                            <h2>Forms</h2>
                            <div className="card">
                                <form className="login_form" id="contactForm">
                                    <div className="form-group">
                                        <input type="email" className="form-control" id="email" name="email" placeholder="Email" required />
                                    </div>
                                    <div className="form-group">
                                        <select className="form-select" aria-label="Default select example">
                                            <option selected>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className="input-group">
                                        <input type={showPassword ? 'text' : 'password'} className="form-control" id="password" name="password" placeholder="Password" required />
                                        <span
                                            className="input-group-text password_visibility"
                                            onClick={togglePasswordVisibility}>{showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className='mb-5'>
                            <h2>Table</h2>

                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">First</th>
                                        <th scope="col">Last</th>
                                        <th scope="col">Handle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Mark</td>
                                        <td>Otto</td>
                                        <td>@mdo</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Jacob</td>
                                        <td>Thornton</td>
                                        <td>@fat</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td colspan="2">Larry the Bird</td>
                                        <td>@twitter</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className='mb-5'>
                            <h2>Product Card</h2>

                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                    <Link to="#" className='link'>
                                        <div className="f_p_item">
                                            <div className="f_p_img">
                                                {/* <img className="img-fluid" src={`${File_URL}/${product.image_url}`} alt="image" onError={handleImageError} /> */}
                                                <img className="img-fluid" src={PRODUCT} alt="image" />
                                                <div className="curve_box">
                                                    <div className="p_icon">
                                                        <a href=''> <FaRegHeart className='p-icon' /> </a>
                                                        <a href=''> <IoCartOutline className='p-icon' /> </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="f_p_body">
                                                <h4 className='p_name'>{"NIKE AIR JORDAN MID"}</h4>
                                                <div className="d-flex jc-center ai-center mb-2">
                                                    <div className="small-ratings me-2">
                                                        <i className="fa fa-star rating-color"></i>
                                                        <i className="fa fa-star rating-color"></i>
                                                        <i className="fa fa-star rating-color"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                    <h4 className="review">1 review</h4>
                                                </div>
                                                <h5>$999</h5>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>


                    </section>
                </div>
            </div>
        </>
    )
}

export default UI_Kit
