import React, { useState } from 'react'
import NavSidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { useProSidebar } from 'react-pro-sidebar';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

function Password() {

    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();

    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    const togglePasswordVisibility3 = () => {
        setShowPassword3(!showPassword3);
    };


    return (
        <>
            <div className="d-flex">
                <div className="sidebar_div">
                    <NavSidebar collapseSidebar={collapseSidebar} toggleSidebar={toggleSidebar} collapsed={collapsed} toggled={toggled} broken={broken} rtl={rtl} />
                </div>
                <div className="page_div">
                    <section className="user_profile_area">
                        <div className="container">

                            <div className="profile_card">
                                <h3>Update Password</h3>
                                <form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="input-group mb-3">
                                                <input type={showPassword1 ? 'text' : 'password'} className="form-control" placeholder="Current Password" required />
                                                <span
                                                    className="input-group-text password_visibility"
                                                    onClick={togglePasswordVisibility1}>{showPassword1 ? <MdVisibilityOff /> : <MdVisibility />}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="input-group mb-3">
                                                <input type={showPassword2 ? 'text' : 'password'} className="form-control" placeholder="New Password" required />
                                                <span
                                                    className="input-group-text password_visibility"
                                                    onClick={togglePasswordVisibility2}>{showPassword2 ? <MdVisibilityOff /> : <MdVisibility />}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="input-group mb-3">
                                                <input type={showPassword3 ? 'text' : 'password'} className="form-control" placeholder="Confirm Password" required />
                                                <span
                                                    className="input-group-text password_visibility"
                                                    onClick={togglePasswordVisibility3}>{showPassword3 ? <MdVisibilityOff /> : <MdVisibility />}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='d-flex jc-end'>
                                        <button className="save_btn">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Password
