import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Banner from '../components/Banner'
import Footer from '../components/Footer'

import "../tracking.css"
import { getOrderWithProducts } from '../ContextAPI/APIs/api'
import { Alert } from '../ContextAPI/Components/notify'
import { useParams } from 'react-router-dom'
import { File_URL } from '../config'
import { handleImageError } from '../helpers/helper'

function Tracking() {

    const {id} = useParams()
    console.log('id',id)
    const [myOrders,setMyOrders]= useState([])
   
    const [page, setPage] = useState(1);
    console.log('pageeeee',page)
    const getOrdersDataWithProducts = async()=>{
        try {
           const response = await getOrderWithProducts(id,page)
           if(response.success) setMyOrders([...myOrders,...response.orders]) 
          
        } catch (error) {
            console.log(error)
            Alert(error.message)
        }
    }
   

    useEffect(()=>{
        getOrdersDataWithProducts()
    },[page])
    
    return (
        <>
            <Navbar />
            <Banner BannerHeading="Order Tracking" PageRoute="/tracking" PageLink="Tracking" />
            <section className="tracking_box_area p_120">
                <div className="card tracking_card">
                    <div className="title">Purchase Receipt</div>
                    <div className="info">
                        <div className="row d-flex jc-between">
                            <div className="col-8">
                                <span id="heading">Date</span><br />
                                <span id="details">{myOrders.length > 0 ? myOrders[0].date : ""}</span>
                            </div>
                            <div className="col-4 pull-right">
                                <span id="heading">Order No.</span><br />
                                <span id="details">{myOrders.length > 0 ? myOrders[0].order_id : ""}</span>
                            </div>
                        </div>
                    </div>
                    <div className="pricing">
                        <div className="row">
                            {myOrders.map((o, i) => (
                                <React.Fragment key={i}>
                                    <div className="col-9">
                                        <span id="name">{o.product_name}</span>
                                    </div>
                                    <div className="col-3">
                                        <span id="price">&pound;{o.price}</span>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                        <div className="row">
                            <div className="col-9">
                                <span id="name">Shipping</span>
                            </div>
                            <div className="col-3">
                                <span id="price">&pound;33.00</span>
                            </div>
                        </div>
                    </div>
                    <div className="total">
                        <div className="row">
                            <div className="col-9"></div>
                            <div className="col-3"><big>&pound;262.99</big></div>
                        </div>
                    </div>
                    <div className="tracking">
                        <div className="title">Tracking Order</div>
                    </div>
                    <div className="progress-track">
                        <ul id="progressbar">
                            <li className="step0 active" id="step1">Pending</li>
                            <li className="step0 active" id="step2">Processing</li>
                            <li className="step0 active text-center" id="step3">Shipped</li>
                            <li className="step0 text-right" id="step4">Delivered</li>
                            <li className="step0 text-right" id="step5">Completed</li>
                        </ul>
                    </div>


                    <div className="footer">
                        <div className="row">
                            <div className="col-2"><img className="img-fluid" src="https://i.imgur.com/YBWc55P.png" /></div>
                            <div className="col-10">Want any help? Please &nbsp;<a> contact us</a></div>
                        </div>
                    </div>

                
                </div>
            </section>
            {/* Table to display orders */}
            <Footer />
        </>
    );
}

export default Tracking
