import React, { useEffect, useState } from "react";
import { Alert } from "../ContextAPI/Components/notify";
import { useParams } from "react-router-dom";
import { addImages } from "../ContextAPI/APIs/api";
import AdminSidebar from "../components/AdminSidebar";


const AddImages = () => {

    const { id } = useParams();

    const [images, setImages] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(images[0]);
            const formData = new FormData();

            formData.append("id", id);
            for (let i = 0; i < images.length; i++) {
                formData.append("images", images[i]);
            }


            const response = await addImages(formData);
            if (response.success) return Alert(response.message);

        } catch (error) {

            console.log(error);
            Alert(error.message);
        }
    };


    return (
        <>

            <div className="d-flex">
                <div className="sidebar_div">
                    <AdminSidebar />
                </div>
                <div className="page_div">
                    <section className="admin_pages add_images_area">
                        <div className="container">
                            <div className="card">
                                <h3>Add Images</h3>
                                <form className="login_form" id="contactForm" onSubmit={(e) => handleSubmit(e)} >
                                    <div className="form-group">
                                        <input type="file" multiple className="form-control" id="stock-quantiy" name="name" placeholder="stock quantity" onChange={(e) => setImages(e.target.files)} required />
                                    </div>

                                    <div className="mb-0">
                                        <button type="submit" className="submit_btn">ADD IMAGES</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};


export default AddImages;