import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import login from "../assets/img/login.jpg"
import Subscription from '../components/Subscription'
import Banner from '../components/Banner'


import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useAuth } from '../ContextAPI/Components/auth'


function Signin() {

    const {Login} = useAuth();

    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        email: "", password: ""
    })

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const handleSubmit = async (e) => {
        e.preventDefault()
        Login(formData);
    }

    return (
        <>

            {/* <!--================Login Box Area =================--> */}
            <section className="login_box_area">
                <div className="container">
                    <div className="" id="accountDiv">
                        {/* <div className="col-lg-6 d-block">
                            <div className="login_box_img">
                                <img className="img-fluid" src={login} alt="image" />
                                <div className="hover">
                                    <h4>New to our website?</h4>
                                    <p>There are advances being made in science and technology everyday, and a good example of this is the</p>
                                    <Link className="main_btn" to="/sign-up">Create an Account</Link>
                                </div>
                            </div>
                        </div> */}
                        <div className="card login_form_inner">
                            <h3>Log in to enter</h3>
                            <form onSubmit={(e) => handleSubmit(e)} className="login_form" id="contactForm">
                                <div className="form-group">
                                    <input value={formData.email} onChange={(e) => setFormData({...formData, email: e.target.value})} type="email" className="form-control" id="email" name="email" placeholder="Email" required/>
                                </div>
                                <div className="input-group mb-3">
                                    <input value={formData.password} onChange={(e) => setFormData({...formData, password: e.target.value})} type={showPassword ? 'text' : 'password'} className="form-control" id="password" name="password" placeholder="Password" required/>
                                    <span
                                        className="input-group-text password_visibility"
                                        onClick={togglePasswordVisibility}>{showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                                    </span>
                                </div>

                                <div className='mb-4'>
                                    <button type="submit" value="submit" className="submit_btn dark_hover_btn">Log In</button>
                                </div>
                            </form>

                            <small>New Here? &nbsp; <Link to="/sign-up" className='Link'> Create your Account </Link></small>

                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================End Login Box Area =================--> */}

        </>
    )
}

export default Signin
