import React, { useEffect, useState } from 'react'
import { Alert } from '../ContextAPI/Components/notify'
import { addVaraitionOption, addVariant, getAllVariants } from '../ContextAPI/APIs/api'
import AdminSidebar from '../components/AdminSidebar'

const AddVariants = () => {

  // name for adding variant
  // const [name,setName] = useState("")
  const [variations, setVariations] = useState([])
  const [variationData, setVariationData] = useState({
    name: ""
  })


  // name for adding variation option name 

  const [formData, setFormData] = useState({
    name: "",
    variation_id: ""
  })

  const addVariation = async (e) => {
    e.preventDefault()
    try {
      const response = await addVariant(variationData)

      if (response.success)
        getAllVariations()
      return Alert(response.message)
    } catch (error) {
      console.log(error)
      Alert(error.message)
    }
  };


  const addVariationOption = async (e) => {
    e.preventDefault()
    try {
      const response = await addVaraitionOption(formData)
      if (response.success) return Alert(response.message)

    } catch (error) {
      console.log(error)
      Alert(error.message)
    }
  }



  // get all variants 

  const getAllVariations = async () => {
    try {
      const response = await getAllVariants()
      if (response.success) setVariations(response?.varaition)
    } catch (error) {
      console.log(error)
      Alert(error.message)
    }
  };

  useEffect(() => {
    getAllVariations();
  }, [])


  return (

    <>

      <div className="d-flex">
        <div className="sidebar_div">
          <AdminSidebar />
        </div>
        <div className="page_div">
          <section className="admin_pages add_variants_area">
            <div className="container">

              <div className="card">
                <h3>Add Variant</h3>
                <form className="login_form" id="contactForm" onSubmit={(e) => addVariation(e)} >
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={variationData.name}
                      onChange={(e) =>
                        setVariationData({ ...variationData, name: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <button type="submit" value="submit" className="btn submit_btn" >
                      Add
                    </button>
                  </div>
                </form>

                {/* form for adding variatio option  */}

                <form className="login_form" id="contactForm" onSubmit={(e) => addVariationOption(e)} >
                  <div className="form-group">
                    <select
                      className='form-select'
                      value={formData.variation_id}
                      onChange={(e) =>
                        setFormData({ ...formData, variation_id: e.target.value })
                      }
                    >
                      <option value="">Variant Name</option>
                      {variations.map((v) => (
                        <option key={v.id} value={v.id}>
                          {v.name}
                        </option>
                      ))}
                    </select>

                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="SKU"
                      name="SKU"
                      placeholder="variation option value  eg:red"
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                      required
                    />
                  </div>


                  <div className="mb-0">
                    <button type="submit" value="submit" className="btn submit_btn" >
                      Add
                    </button>
                  </div>
                </form>
              </div>

            </div>
          </section>
        </div>
      </div>

    </>
  )
}

export default AddVariants
