import axios from "axios";
import { API_URL } from "../../config";
import { Alert } from "../Components/notify";

/** --------------- AUTH --------------- **/
export const signUp = async (body) => {
  try {
    return await CallAPI("POST", "api/user/register/", body);
  } catch (error) {
    throw error;
  }
};

export const signin = async (body) => {
  try {
    return await CallAPI("POST", "api/user/login", body);
  } catch (error) {
    throw error;
  }
};

export const forgetPassword = async (body) => {
  try {
    return await CallAPI("POST", "user/forget-password", body);
  } catch (error) {
    throw error;
  }
};

export const getLoginUser = async () => {
  try {
    return await CallAPI("GET", "api/user/get-login-user");
  } catch (error) {
    throw error;
  }
};

/**
 * Dashboard APIs Section
 */

/** ----------------- CATEGORY ----------------- **/
export const addCategory = async (body) => {
  try {
    return await CallAPI("POST", "api/product-category/add-category", body);
  } catch (error) {
    throw error;
  }
};

export const getAllCategories = async (type = "sub") => {
  try {
    return await CallAPI("GET", `api/product-category/get`, null, type);
  } catch (error) {
    throw error;
  }
};

/** --------------- SUB-CATEGORY --------------- **/
export const addSubCategory = async (body) => {
  try {
    return await CallAPI("POST", "api/subcategory/create", body);
  } catch (error) {
    throw error;
  }
};

/** ------------------ COLORS ------------------ **/
export const addColors = async (body) => {
  try {
    return await CallAPI("POST", "api/color/add", body);
  } catch (error) {
    throw error;
  }
};

/** ------------------ PRODUCTS ------------------ **/
export const addProduct = async (body) => {
  try {
    return await CallAPI("POST", "api/product/add-product", body);
  } catch (error) {
    throw error;
  }
};

export const addProductVariant = async (body) => {
  try {
    return await CallAPI("POST", "api/product-config/add-config", body);
  } catch (error) {
    throw error;
  }
};

export const getProduct = async () => {
  try {
    return await CallAPI("GET", "api/product/get");
  } catch (error) {
    throw error;
  }
};

export const getSingleProduct = async (id) => {
  try {
    return await CallAPI("GET", `api/product/get-single/${id}`);
  } catch (error) {
    throw error;
  }
};

export const updateProduct = async (id, body) => {
  try {
    return await CallAPI('PUT', `api/product/update-product/${id}`, body)
  } catch (error) {
    throw error;
  }
};

export const deleteProduct = async (body) => {
  try {
    // return await CallAPI ('DELETE', `api/product/delete-product/${id}`)
  } catch (error) {
    throw error;
  }
};

// filter products

export const filterProduct = async (queryParams) => {
  try {
    return await CallAPI("GET", `api/product/filtered-products`, null, queryParams);

  } catch (error) {
    throw error;
  }
};



// add product image 

export const addProductImage = async (id,body)=>{
  try {
    return await CallAPI("POST",`api/product/add-image/${id}`,body)
  } catch (error) {
    throw error
  }
}


export const deleteProductImage = async(body)=>{
  try {
    return await CallAPI("POST",`api/product/delete-image`,body)
  } catch (error) {
    throw error
  }
}


//  get product by page 

export const getProductByPages = async (page) => {
  try {
    return await CallAPI('GET', `api/product/get-product/${page}`)
  } catch (error) {
    throw error
  }
}

export const getAllColors = async () => {
  try {
    return await CallAPI("GET", `api/product/get`);
  } catch (error) {
    throw error;
  }
};

export const getAllVariants = async () => {
  try {
    return await CallAPI("GET", "api/variation/get");
  } catch (error) {
    throw error;
  }
};

export const getAllVariationBasisOnVariant = async (variant) => {
  try {
    return await CallAPI("GET", `api/variation-option/get-variants?name=${variant}`);
  } catch (error) {
    throw error;
  }
};

// add variant
export const addVariant = async (body) => {
  try {
    return await CallAPI("POST", "api/variation/add/", body);
  } catch (error) {
    throw error;
  }
};

// add variation option
export const addVaraitionOption = async (body) => {
  try {
    return await CallAPI("POST", "api/variation-option/add-option", body);
  } catch (error) {
    throw error;
  }
};


// get product varainats
export const getProductVariants = async (id) => {
  try {
    return await CallAPI("GET", `api/product-config/get-single/${id}`);
  } catch (error) {
    throw error;
  }
};


// get all variations 
export const getAllVariation = async () => {
  try {
    return await CallAPI('GET', `api/variation-option/get-all-variations`)
  } catch (error) {
    throw error
  }
}

// get variation item
export const getOptionsByIds = async (ids) => {
  try {
    return await CallAPI(
      "GET",
      `api/variation-option/get-items-by-ids?list=${ids}`
    );
  } catch (error) {
    throw error;
  }
};

// get product variant options with name

export const getVariantOptions = async () => {
  try {
    return await CallAPI("GET", `api/cart/variation/get-variation`);
  } catch (error) {
    throw error;
  }
};

export const addToCart = async (id, body) => {
  try {
    return await CallAPI("POST", `api/cart/test-new-cart/${id}`, body);
  } catch (error) {
    throw error;
  }
};

// get carts of users
export const getCarts = async () => {
  try {
    return await CallAPI("GET", `api/cart/get-carts`);
  } catch (error) {
    throw error;
  }
};



// deleet cart item 

export const DeleteCartItem = async (body) => {
  try {
    return await CallAPI("POST", `api/cart/delete-item`, body);
  } catch (error) {
    throw error;
  }
};






// Add Images to Product
export const addImages = async (body) => {
  try {
    return await CallAPI("POST", `api/images/add-images`, body);
  } catch (error) {
    throw error;
  }



};



// get images 



export const getImages = async()=>{
  try {
    return await CallAPI("GET",`api/images/get-images`)
  } catch (error) {
    throw error
  }
}


// get payment methids 
export const getPaymentMethods = async () => {
  try {
    return await CallAPI('GET', 'api/order/payment-type')
  } catch (error) {
    throw error
  }
};







// order place api 


export const placeOrder = async (body) => {
  try {
    return await CallAPI('POST', `api/order/order`, body)
  } catch (error) {
    throw error
  }
}


// get order of user 

export const getOrders = async (page) => {
  try {
    return await CallAPI('GET', `api/order/get-orders/?page=${page}`)
  } catch (error) {
    throw error
  }
}


export const getOrderWithProducts = async (id, page) => {
  try {
    return await CallAPI('GET', `api/order/get-orders-with-product/${id}/?page=${page}`);
  } catch (error) {
    throw error
  }
}




// add addresses 



export const addAddress = async (body) => {
  try {
    return await CallAPI('POST', `api/address/add-address`, body)
  } catch (error) {
    throw error

  }

}

export const getAddreses = async () => {
  try {
    return await CallAPI('GET', `api/address/get`)
  } catch (error) {
    throw error
  }
}






/**  ---------------------------------------------------------------------------------------------------------- **/

const CallAPI = async (
  method,
  endpoint,
  data = null,
  queryParams = null
) => {
  try {
    let response;

    switch (method) {
      case "GET":
        console.log("data", data);
        console.log("queryParams", queryParams);
        response = await axios.get(`${API_URL}/${endpoint}`, {
          params: queryParams,
        });
        break;

      case "POST":
        response = await axios.post(`${API_URL}/${endpoint}`, data, {
          params: queryParams,
        });
        break;

      case "PUT":
        response = await axios.put(`${API_URL}/${endpoint}`, data, {
          params: queryParams,
        });
        break;

      case "PATCH":
        response = await axios.patch(`${API_URL}/${endpoint}`, data, {
          params: queryParams,
        });
        break;

      case "DELETE":
        response = await axios.delete(`${API_URL}/${endpoint}`, data);
        break;

      default:
        throw new Error("Invalid HTTP method");
    }

    console.log(response.data);
    if (typeof response.data == "undefined") Alert("Error in API call");
    return response.data;
  } catch (error) {
    console.error(error);
    if (typeof error.response == "undefined") Alert("Server not responding");
    throw error.response ? error.response.data : error;
  }
};
