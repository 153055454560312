import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import { useAuth } from '../ContextAPI/Components/auth';


import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar, sidebarClasses } from 'react-pro-sidebar';

import { HiBars3, HiOutlineUserCircle } from "react-icons/hi2";
import { TbLogout2 } from 'react-icons/tb';
import { IoBagHandleOutline, IoHomeOutline, IoLocationOutline } from 'react-icons/io5';

import logo from "../assets/images/Nike.png"
import logo2 from "../assets/images/Nike.png"
import { usePathname } from '../ContextAPI/Components/PathnameContext';
import { RiLockPasswordLine } from 'react-icons/ri';


function NavSidebar({ collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl }) {

    const { user, SignOut } = useAuth();

    const { pathname: currentPath } = useLocation();

    const { style } = usePathname();
    const navigate = useNavigate()

    const isSmallMobile = useMediaQuery({ query: '(max-width: 501px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    function newfunction() {
        const sidebar = window.document.getElementById("sideBar")
        console.log("siderbar", sidebar);
        sidebar.style.height = "100%"
        sidebar.style.zIndex = "1"
        if (collapsed) {
            sidebar.style.position = "fixed"
            sidebar.style.width = "50%"
        }
        else {
            sidebar.style.position = "unset"
        }
    }


    return (
        <>
            <div className='sidebar_main'>
                <Sidebar
                    defaultCollapsed={isTablet ? true : false}
                    // defaultCollapsed={false}
                    id="sideBar"
                    className='navsidebar'
                    rootStyles={{
                        [`.${sidebarClasses.container}`]: {
                            padding: "10px",
                            width: "100%"
                        },
                    }}
                >
                    <Menu
                        menuItemStyles={{
                            button: ({ level, active, disabled }) => {
                                // only apply styles on first level elements of the tree
                                if (level == 0)
                                    return {
                                        color: disabled ? '#f5d9ff' : '#d359ff',
                                        backgroundColor: active ? '#eecef9' : undefined,
                                        paddingRight: "5px",
                                        paddingLeft: "5px"
                                    };
                            },
                        }}
                    >

                        <MenuItem className='py-3 sidebar_toggle'>
                            <div className='d-flex jc-between ai-center'>
                                {
                                    collapsed ?
                                        // <Link to="/" className="activeLink1 hash-tag text-truncate">
                                        //     <img src={logo2} alt="logo" className='img-fluid' style={{ width: "40px" }} />
                                        // </Link>
                                        ""
                                        :
                                        <>
                                            <Link to={"/"} className="" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                                <img src={logo} alt="logo" className='img-fluid' style={{ width: "50px" }} />
                                            </Link>
                                        </>
                                }
                                <main className="oo78uijk">
                                    {
                                        isSmallMobile ?
                                            <button className="btnFocus" onClick={() => { collapseSidebar(); console.log("collapsed", collapsed); newfunction() }}><HiBars3 className="mr-0 sidebar_toggle_icon" /></button>
                                            :
                                            <button className="btnFocus" onClick={() => { collapseSidebar(); console.log("collapsed", collapsed) }}><HiBars3 className="mr-0 sidebar_toggle_icon" /></button>
                                    }
                                </main>
                            </div>
                        </MenuItem>

                        {/* <h6>GLOBAL</h6> */}
                        <Link to="/" className='link'>
                            <MenuItem className={`${style == "home" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<IoHomeOutline className={`${style == "home" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                <Link to="/" className={`mx-1 my-1 ${style == "home" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Home </Link>
                            </MenuItem>
                        </Link>
                        <Link to="/profile" className='link'>
                            <MenuItem className={`${style == "profile" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<HiOutlineUserCircle className={`${style == "profile" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                <Link to="/profile" className={`mx-1 my-1 ${style == "profile" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Profile </Link>
                            </MenuItem>
                        </Link>
                        <Link to="/addresses" className='link'>
                            <MenuItem className={`${style == "addresses" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<IoLocationOutline className={`${style == "addresses" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                <Link to="/addresses" className={`mx-1 my-1 ${style == "addresses" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Address Book </Link>
                            </MenuItem>
                        </Link>
                        <Link to="/my-orders" className='link'>
                            <MenuItem className={`${style == "my_orders" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<IoBagHandleOutline className={`${style == "my_orders" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                <Link to="/my-orders" className={`mx-1 my-1 ${style == "my_orders" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> My Orders </Link>
                            </MenuItem>
                        </Link>
                        <Link to="/password" className='link'>
                            <MenuItem className={`${style == "password" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<RiLockPasswordLine className={`${style == "password" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                                <Link to="/password" className={`mx-1 my-1 ${style == "password" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`} style={{ width: "maxContent" }}> Password </Link>
                            </MenuItem>
                        </Link>
                        <MenuItem className={`${style == "log_out" ? "active" : "unactive"} ${collapsed == true ? 'd-flex jc-center' : ''}`} icon={<TbLogout2 className={`${style == "log_out" ? "active_sidebar_icon" : "sidebar_icon"}`} />}>
                            <button
                                className={`mx-1 my-1 log_out_btn ${style == "log_out" ? "activeLink" : "link"} ${collapsed == true ? 'd-none' : 'd-block'}`}
                                style={{ width: "maxContent" }}

                                onClick={() => SignOut()}
                            > Log Out </button>
                        </MenuItem>
                    </Menu>

                </Sidebar>
            </div>
        </>
    )
}

export default NavSidebar