import React, { useEffect, useState } from "react";
import logo from "../assets/images/Nike.png";
import { FaHeart, FaShoppingCart, FaUser } from "react-icons/fa";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import { Alert } from "react-bootstrap";
import { getAllCategories } from "../ContextAPI/APIs/api";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useAuth } from "../ContextAPI/Components/auth";
import { useCookies } from "react-cookie";
import { useProductFilter } from "../ContextAPI/Components/productFilter";
import { Alert } from "../ContextAPI/Components/notify";
import { useMediaQuery } from "react-responsive";


function Navbar() {
  const navigate = useNavigate();
  const { SignOut } = useAuth();
  const { __setFilters, setRenderCount, handleProductFilter, handleSubmit, filterHandler } = useProductFilter()
  const [cookies, setCookie, removeCookie] = useCookies();
  const [categories, setCategories] = useState([]);


  const getCategories = async () => {
    try {
      const response = await getAllCategories({});
      console.log(response);
      if (response.success) setCategories(response.categories);
    } catch (error) {
      console.log("errro", error);
      Alert(error.message, "error");
    }
  };

  useEffect(() => {
    getCategories();
  }, []);


  const redirection = (url, cID) => {
    if (url != "") navigate(url)
    if (cID != "") {
      __setFilters({
        category: [cID.toString()],
        color: [],
        size: [],
        material: [],
        minPrice: "",
        maxPrice: "",
      });

      setRenderCount((prev) => prev + 1);
    }
    else {
      __setFilters({
        category: [],
        color: [],
        size: [],
        material: [],
        minPrice: "",
        maxPrice: "",
      });

      setRenderCount(0)
    }
  }


  const isTablet = useMediaQuery({ query: "(max-width: 992px)" });



  return (
    <>
      {/* <!--================Header Menu Area =================--> */}
      <header className="header_area">
        <div className="main_menu">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              {/* <!-- Brand and toggle get grouped for better mobile display --> */}
              <Link className="navbar-brand logo_h" to="/">
                <img src={logo} alt="logo" className="img-fluid" />
              </Link>

              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
              <div className="collapse navbar-collapse offset" id="navbarSupportedContent" >
                <div className="row w-100">
                  <div className="col-lg-7">
                    <ul className="nav navbar-nav center_nav justify-content-center">
                      <li className="nav-item">
                        <NavLink to="/" activeClassName="active">Home</NavLink>
                      </li>
                      <li className="nav-item" onClick={() => redirection(``, [])}>
                        <NavLink to="/shop" activeClassName="active">Shop</NavLink>
                      </li>

                      <li className="nav-item">
                        <div className="dropdown">
                          <button className="nav-link categ_dropdown" type="button" id="dropdownMenuButton" data-mdb-toggle="dropdown" aria-expanded="false">
                            Categories
                          </button>

                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton"  >
                            {categories?.map((c) => {
                              return (
                                <li className="dropdown-item d-flex jc-between cursorPointer" onClick={() => redirection(`/shop?category=${c.id}`, c.id)}>
                                  <div>{c.category_name}</div>
                                  <div> {c.subCategory.length > 0 && (<MdKeyboardDoubleArrowRight />)} </div>

                                  {/* <NavLink to={`/shop?category=${c.id}`} activeClassName="active"> </NavLink> */}

                                  {c.subCategory.length > 0 && (
                                    <ul className="dropdown-menu dropdown-submenu">
                                      {c.subCategory?.map((s) => {
                                        return (
                                          <li className="dropdown-item cursorPointer" onClick={() => redirection(`/shop?category=${s.id}`, s.id)}>
                                            {s.category_name}
                                            {/* <Link to={`/shop?category=${s.id}`} className="dropdown-item" activeClassName="active">    </Link> */}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </li>

                      {
                        isTablet &&
                        <>
                          <li className="nav-item">
                            <NavLink to="/profile" activeClassName="active">Profile</NavLink>
                          </li>

                          <li className="nav-item">
                            <NavLink to="/my-orders" activeClassName="active">My Orders</NavLink>
                          </li>

                          <li className="nav-item">
                            <NavLink to="/cart" activeClassName="active">Cart</NavLink>
                          </li>

                          <li className="nav-item">
                            <Link to="#" activeClassName="active" onClick={() => SignOut()}>Sign Out</Link>
                          </li>
                        </>
                      }

                      {/* <li className="nav-item">
                        <Menu />
                      </li> */}
                    </ul>
                  </div>


                  {
                    !isTablet &&
                    <div className="col-lg-5">
                      <ul className="nav navbar-nav navbar-right right_nav pull-right">
                        <hr />
                        {cookies.pk2 == undefined ? (
                          <>
                            <li className="nav-item">
                              <NavLink to="/sign-in" activeClassName="active">
                                <FaUser />
                              </NavLink>
                            </li>

                            <hr />
                            <hr />
                          </>
                        ) : (
                          <>
                            <div className="dropdown account_dropdown d-flex ai-center">
                              <button className="nav-link account_dropdown_btn dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false" >
                                <FaUser />
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                <li className="dropdown-item">
                                  <NavLink to="/profile" activeClassName="active">Profile</NavLink>
                                </li>
                                <li className="dropdown-item">
                                  <NavLink to="/admin/dashboard" activeClassName="active" >
                                    Dashboard
                                  </NavLink>
                                </li>
                                <li className="dropdown-item" onClick={() => SignOut()} style={{ cursor: 'pointer' }} >
                                  Sign Out
                                </li>
                              </ul>
                            </div>
                          </>
                        )}

                        <hr />

                        {/* <li className="nav-item">
                        <NavLink to="/wishlist" activeClassName="active">
                          <FaHeart />
                        </NavLink>
                      </li> */}

                        <hr />

                        <li className="nav-item">
                          <NavLink to="/cart" activeClassName="active">
                            <FaShoppingCart />
                          </NavLink>
                        </li>

                        <hr />
                      </ul>
                    </div>
                  }
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
      {/* <!--================Header Menu Area =================--> */}
    </>
  );
}


export default Navbar;