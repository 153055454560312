import React, { useEffect, useState } from "react";
import { getOptionsByIds } from "../ContextAPI/APIs/api";

function Material({ Variants, onMaterialClick, cartData }) {

  console.log("Material List", Variants);
  
  if (Variants.length == 0) return <></>;

  return (
    <>
      <div className="s_product_sizes">
        <h5>Material</h5>
        <div className="d-flex flex-wrap gap-2">
          {Array.from(new Map(Variants.map(item => [item.material, item])).values()).map((item, i) => {
            return <button key={i} disabled={item.stock_quantity == 0 || cartData.color_id != item.color} className={`${item.stock_quantity <= 0 || item.color != cartData.color_id ? "size_unavailable" : cartData.material_id == item.material ? "sizeSelected" : "defaultTagStyle" }`} onClick={() => { onMaterialClick(item.material, 'material'); console.log((item.material)) }} >
              {item.material}
            </button>
          })}
        </div>
      </div>
    </>
  );
}

export default Material;
