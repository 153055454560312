import React, { useEffect, useState } from "react";
import { getOptionsByIds } from "../ContextAPI/APIs/api";

function Sizes({ Variants, onSizeClick, cartData }) {

  if (Variants.length == 0) return <></>

  return (
    <>
      <div className="s_product_sizes">
        <h5>Size</h5>
        <div className="d-flex flex-wrap gap-2">
          {Array.from(new Map(Variants.map(item => [item.size, item])).values()).map((item, i) => {
            return <button key={i} disabled={item.stock_quantity == 0 || cartData.color_id != item.color} className={`${item.stock_quantity <= 0 || item.color != cartData.color_id ? "size_unavailable" : cartData.size_id == item.size ? "sizeSelected" : "defaultTagStyle" }`} onClick={() => { onSizeClick(item.size, 'size'); console.log((item.size)) }}> {item.size} </button >
          })}
        </div>
      </div >
    </>
  );
}

export default Sizes;
