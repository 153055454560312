import { createContext, useContext, useState, useEffect } from "react"
import { signin, getLoginUser } from "../APIs/api";
import { useCookies } from 'react-cookie';
import { Alert } from "./notify";
import { useNavigate } from "react-router-dom";



// Step 1
const AuthContext = createContext()

// Step 2
export const useAuth = () => {
    return useContext(AuthContext);
}

// Step 3
export const AuthProvider = ({ children }) => {
    const navigate = useNavigate()
    const [user, setUser] = useState(null);
    const [cookies, setCookie, removeCookie] = useCookies();
    const [formData, setFormData] = useState({
        name: "",
        short_description: "",
        long_description: "",
        price: "",
        stock_management: "",
        total_quantity: "",
        SKU: "",
        category_id: "",
        images:[""],
        image_url:"",
        status:""

    });

    async function Login(body) {
        try {
            const response = await signin(body)
            console.log("RREESS", response);

            if(response.user.status != "active") return Alert("Your account is Blocked", "error")            
            
            if(response.success)  {
                Alert(response.message)
                setUser(response.user)
                setCookie('pk2', response.token, {
                    path: '/',
                    maxAge: 6000000,
                });
                
                return navigate("/")
            }

        } catch (error) {
            Alert(error.message, "error")
        }



    }

    async function SignUp(body) {
        // const res = await signup_user(body)
        // console.log("res", res);
        // return res
    }

    async function GetLoginUSer() {
        try {
            const response = await getLoginUser()
            console.log("RESPONSE", response);
            if(response.success) setUser(response?.user)    
            
        } catch (error) {
            console.log("errorerrorerror", error);
            if(error.signout) SignOut()
            if(error.redirect) return navigate(error.redirect)
        }


    }

    function SignOut() {
        removeCookie("pk2")
        navigate('/sign-in')
        console.log("hello")
    }


    return (
        <AuthContext.Provider value={{ user, setUser, Login, SignUp, GetLoginUSer,SignOut ,setFormData,formData}}>
            {children}
        </AuthContext.Provider>
    )
}


