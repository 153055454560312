import { createContext, useContext, useState, useEffect } from "react"
import { useLocation } from 'react-router-dom';


// Step 1
const PathnameContext = createContext()

// Step 2
export const usePathname = () => {
    return useContext(PathnameContext);
}

// Step 3
export const PathnameProvider = ({ children }) => {
    const location = useLocation()

    const [style, setStyle] = useState(sessionStorage.getItem("a") || "home")

    useEffect(() => {
        console.log("location.pathname", location.pathname);
        handlePathname()

    }, [location.pathname]);

    const handlePathname = () => {
        // ADMIN DASHBOARD
        if (window.location.pathname == "/admin/dashboard") {
            sessionStorage.setItem("a", "admin_dashboard")
            setStyle("admin_dashboard")
        }
        if (window.location.pathname == "/admin/products") {
            sessionStorage.setItem("a", "products")
            setStyle("products")
        }
        if (window.location.pathname == "/admin/add-product") {
            sessionStorage.setItem("a", "add_product")
            setStyle("add_product")
        }
        if (window.location.pathname == "/admin/add-images/:id") {
            sessionStorage.setItem("a", "add_images")
            setStyle("add_images")
        }
        if (window.location.pathname == "/admin/add-product-category") {
            sessionStorage.setItem("a", "add_product_category")
            setStyle("add_product_category")
        }
        if (window.location.pathname == "/admin/add-variant") {
            sessionStorage.setItem("a", "add_variant")
            setStyle("add_variant")
        }
        if (window.location.pathname == "/admin/add-product-variant/:id/:category") {
            sessionStorage.setItem("a", "add_product_variant")
            setStyle("add_product_variant")
        }

        if (window.location.pathname == "/admin/ui-kit") {
            sessionStorage.setItem("a", "ui_kit")
            setStyle("ui_kit")
        }

        // USER DASHBOARD
        if (window.location.pathname == "/") {
            sessionStorage.setItem("a", "home")
            setStyle("home")
        }
        if (window.location.pathname == "/profile") {
            sessionStorage.setItem("a", "profile")
            setStyle("profile")
        }
        if (window.location.pathname == "/addresses") {
            sessionStorage.setItem("a", "addresses")
            setStyle("addresses")
        }
        if (window.location.pathname == "/my-orders") {
            sessionStorage.setItem("a", "my_orders")
            setStyle("my_orders")
        }
        if (window.location.pathname == "/password") {
            sessionStorage.setItem("a", "password")
            setStyle("password")
        }

        if (window.location.pathname == "/log-out") {
            sessionStorage.setItem("a", "log_out")
            setStyle("log_out")
        }
    }

    return (
        <PathnameContext.Provider value={{ handlePathname, style }}>
            {children}
        </PathnameContext.Provider>
    )
}