import React, { useEffect, useState } from "react";
import {
  addProductVariant,
  getAllCategories,
  getAllVariants,
  getAllVariationBasisOnVariant,
  getProduct,
} from "../ContextAPI/APIs/api";
import { Alert } from "../ContextAPI/Components/notify";
import { useParams } from "react-router-dom";
import AdminSidebar from "../components/AdminSidebar";

const AddProductVariants = () => {

  const { id, category } = useParams();

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [variations, setVariations] = useState([]);
  const [variationOption, setVariationOption] = useState({
    color: [],
    size: [],
    material: [],
  });
  const [initialData, setInitialData] = useState(
    {
      product_id: id,
      category_id: category,
      size_id: null,
      color_id: null,
      material_id: null,
      stock_quantity: "",
      price: "",
    },
  );

  const [formData, setFormData] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(formData);
      const response = await addProductVariant(formData);
      if (response.success) return Alert(response.message);

    } catch (error) {
      Alert(error.message);
    }
  };

  // get all categories
  const getCategories = async () => {
    console.log(">>>>>>>>>>>>>>>>>>>>");
    try {
      const response = await getAllCategories("simple");
      console.log(response);
      if (response.success) setCategories(response.categories);
    } catch (error) {
      console.log("errro", error);
      Alert(error.message, "error");
    }
  };

  //   get all products
  const getAllProducts = async () => {
    try {
      const response = await getProduct();
      console.log("response", response);
      if (response.success) setProducts(response?.product);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAllVariations = async () => {
    try {
      const response = await getAllVariants();
      if (response.success) setVariations(response?.varaition);
    } catch (error) {
      console.log(error);
      Alert(error.message);
    }
  };

  const getVariationBasisOnVariant = async (fieldName) => {
    try {
      const response = await getAllVariationBasisOnVariant(fieldName);
      if (response.success)
        setVariationOption((prevState) => ({
          ...prevState,
          [fieldName]: response.variantOption,
        }));
    } catch (error) {
      Alert(error.message);
    }
  };

  const pushInitialData = async () => {
    // console.log(
    //   !initialData.size && !initialData.material && !initialData.color
    // );
    if (!initialData.stock_quantity || !initialData.price) {
      return Alert("Please Add Price and Quantitiy");
    } else if (
      !initialData.size_id &&
      !initialData.color_id &&
      !initialData.material_id
    ) {
      return Alert("Please Select One Variant");
    }

    setFormData([...formData, initialData]);
    console.log([...formData, initialData]);
  };

  const AddAnotherVariant = () => {
    setInitialData({
      product_id: id,
      category_id: category,
      size_id: null,
      color_id: null,
      material_id: null,
      stock_quantity: "",
      price: "",
    });
  };

  useEffect(() => {
    getCategories();
    getAllProducts();
    getAllVariations();
    // getVariationBasisOnVariant();
  }, []);

  useEffect(() => {
    getVariationBasisOnVariant("size");
    getVariationBasisOnVariant("color");
    getVariationBasisOnVariant("material");
  }, []);


  return (
    <>

      <div className="d-flex">
        <div className="sidebar_div">
          <AdminSidebar />
        </div>
        <div className="page_div">
          <section className="admin_pages add_variants_area">
            <div className="container">
              <div className="card">
                <h3>Add Product Variant</h3>
                <form className="login_form" id="contactForm" onSubmit={(e) => handleSubmit(e)} >
                  <div className="form-group">
                    <select
                      className="form-select"
                      value={initialData.product_id}
                      onChange={(e) => setInitialData({ ...initialData, product_id: e.target.value, })}>
                      <option value="">Select Product</option>
                      {products.map((p) => (
                        <option key={p.id} value={p.id}>
                          {p.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <select
                      className="form-select"
                      value={category}
                      onChange={(e) => setInitialData({ ...initialData, category_id: e.target.value, })} >
                      <option value="">Select Category</option>
                      {categories.map((c) => (
                        <option key={c.id} value={c.id}>
                          {c.category_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <select
                      className="form-select"
                      value={initialData.size_id}
                      onChange={(e) => setInitialData({ ...initialData, size_id: e.target.value, })} >
                      <option value="size">Select Size</option>
                      {variationOption.size.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <select
                      className="form-select"
                      value={initialData.material_id}
                      onChange={(e) => setInitialData({ ...initialData, material_id: e.target.value, })} >
                      <option value="size">Select Material</option>
                      {variationOption.material.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* <div className="form-group">
                    <select
                      value={initialData.material_id}
                      onChange={(e) =>
                        setInitialData({ ...initialData, material_id: e.target.value })
                      }
                    >
                      <option value="">material</option>
                      {variationOption.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.name}
                        </option>
                      ))}
                    </select>
                  </div> */}

                  {/* <p>{JSON.stringify(variationOption)}</p> */}
                  <div className="form-group">
                    <select
                      className="form-select"
                      value={initialData.color_id}
                      onChange={(e) => setInitialData({ ...initialData, color_id: e.target.value, })} >
                      <option value="">Select Color</option>
                      {variationOption.color.map((c) => (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="stock-quantiy"
                      name="name"
                      placeholder="Stock Quantity"
                      value={initialData.stock_quantity}
                      onChange={(e) => setInitialData({ ...initialData, stock_quantity: e.target.value, })}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control"
                      id="price"
                      name="price"
                      placeholder="Price"
                      value={initialData.price}
                      onChange={(e) => setInitialData({ ...initialData, price: e.target.value })}
                      required
                    />
                  </div>

                  <div className="mb-0">
                    <button onClick={pushInitialData} type="button" className="btn submit_btn" >
                      Add Variant
                    </button>
                  </div>

                  {formData.map((e, i) => {
                    return (
                      <div>
                        {e?.size_id}, {e?.color_id}, {e?.material_id}, {e?.price},{" "}
                        {e?.stock_quantity}
                      </div>
                    );
                  })}

                  {formData.length > 0 && (
                    <>
                      <div className="mb-4">
                        <button type="button" onClick={AddAnotherVariant} className="submit_btn" >
                          ADD ANOTHER VARIANT
                        </button>
                      </div>

                      <div className="mb-4">
                        <button type="submit" className="submit_btn">
                          SAVE VARIANTS
                        </button>
                      </div>
                    </>
                  )}

                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default AddProductVariants;
