import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import login from "../assets/img/login.jpg"
import Banner from '../components/Banner'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import axios from 'axios';
import { signUp } from '../ContextAPI/APIs/api';
import { Alert } from '../ContextAPI/Components/notify';
// import signUp from '../ContextAPI/APIs'

function Signup() {
    
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        phone: "",
        confirmPassword:"",
    });


    const handleSubmit = async (e)=>{
        e.preventDefault()
        try {
            if(formData.password !== formData.confirmPassword) return Alert("password should be matched", "error")

            const response = await signUp(formData)
            console.log('response', response);
            Alert(response.message)
            if(response.success) return navigate("/sign-in")

        } catch (error) {
            Alert(error.message, "error")
        }
    }

    const togglePasswordVisibility = () => setShowPassword(!showPassword);



    return (
        <>

            {/* <!--================Login Box Area =================--> */}
            <section className="login_box_area">
                <div className="container">
                    <div className="">
                        {/* <div className="col-lg-6">
                            <div className="login_box_img">
                                <img className="img-fluid" src={login} alt="image" />
                                <div className="hover">
                                    <h4>New to our website?</h4>
                                    <p>There are advances being made in science and technology everyday, and a good example of this is the</p>
                                    <Link className="main_btn" to="/sign-in">Login To Your Account</Link>
                                </div>
                            </div>
                        </div> */}
                        <div className="card login_form_inner reg_form">
                            <h3>Create an Account</h3>
                            <form className="login_form" onSubmit={(e) => handleSubmit(e)} id="contactForm">
                                <div className="form-group">
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Name" 
                                    value={formData.name} onChange={(e)=>setFormData({...formData, name: e.target.value})} required />
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control" id="email" name="email" placeholder="Email Address"
                                    value={formData.email} onChange={(e)=> setFormData({...formData,email:e.target.value})} required />
                                </div>
                                <div className="input-group mb-3">
                                    <input type={showPassword ? 'text' : 'password'} className="form-control" id="password" name="password" placeholder="Password" 
                                    value={formData.password} onChange={(e)=> setFormData({...formData,password:e.target.value})} required />
                                    <span
                                        className="input-group-text password_visibility"
                                        onClick={togglePasswordVisibility}>{showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                                    </span>
                                </div>
                                <div className="input-group mb-3">
                                    <input type={showPassword ? 'text' : 'password'} className="form-control" id="pass" name="pass" placeholder="Confirm password"
                                      value={formData.confirmPassword} onChange={(e)=> setFormData({...formData,confirmPassword:e.target.value})} required />
                                    <span
                                        className="input-group-text password_visibility"
                                        onClick={togglePasswordVisibility}>{showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                                    </span>
                                </div>
                                <div className="mb-4">
                                    <button type="submit" value="submit" className="submit_btn dark_hover_btn">Register</button>
                                </div>
                            </form>

                            <small>Already have an account? &nbsp; <Link to="/sign-in" className='Link'> Sign In </Link></small>

                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================End Login Box Area =================--> */}
        </>
    )
}

export default Signup
