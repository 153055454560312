import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import client1 from "../../assets/img/clients-logo/c-logo-1.png";
import client2 from "../../assets/img/clients-logo/c-logo-2.png";
import client3 from "../../assets/img/clients-logo/c-logo-3.png";
import client4 from "../../assets/img/clients-logo/c-logo-4.png";
import client5 from "../../assets/img/clients-logo/c-logo-5.png";


function ClientsSlider() {
    return (
        <>

            <Swiper
                slidesPerView={7}
                loop={true}
                spaceBetween={30}
                centeredSlides={true}
                autoplay={true}
                pagination={false}
                breakpoints={{
                    300: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                        centeredSlides: false
                    },
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                        centeredSlides: false
                    },
                    576: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                    992: {
                        slidesPerView: 5,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 40,
                    },
                }}
                modules={[Pagination]}
                className="mySwiper"
            >

                <div className="clients_slider">

                    <SwiperSlide>
                        <div className="clients_item">
                            <img src={client1} alt="image" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="clients_item">
                            <img src={client2} alt="image" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="clients_item">
                            <img src={client3} alt="image" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="clients_item">
                            <img src={client4} alt="image" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="clients_item">
                            <img src={client5} alt="image" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="clients_item">
                            <img src={client1} alt="image" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="clients_item">
                            <img src={client2} alt="image" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="clients_item">
                            <img src={client3} alt="image" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="clients_item">
                            <img src={client4} alt="image" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="clients_item">
                            <img src={client5} alt="image" />
                        </div>
                    </SwiperSlide>
                </div>

            </Swiper>


        </>
    )
}

export default ClientsSlider
