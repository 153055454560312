import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/AdminSidebar";
import ProductData from "./ProductData";

const EditProduct = () => {

    return (
        <>

            <div className="d-flex">
                <div className="sidebar_div">
                    <AdminSidebar />
                </div>
                <div className="page_div">
                    <section className="admin_pages edit_product_area">
                        <div className="container">

                            {/* <h3>Edit Product</h3> */}
                            {/* <h5>Product Details</h5> */}


                            <ProductData  />

                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};


export default EditProduct;