import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// import "./Slider.css";

import cat_image_1 from "../../assets/images/Category/cat-1.webp"
import cat_image_2 from "../../assets/images/Category/cat-2.webp"
import cat_image_3 from "../../assets/images/Category/cat-3.webp"
import cat_image_4 from "../../assets/images/Category/cat-4.webp"


function CategorySlider() {
    const settings = {
        className: "center",
        dots: true,
        autoplay: true,
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 4,
        speed: 500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    centerPadding: "50px",
                    slidesToShow: 3,
                }
            }, {
                breakpoint: 992,
                settings: {
                    centerPadding: "50px",
                    slidesToShow: 3,
                }
            }, {
                breakpoint: 768,
                settings: {
                    centerPadding: "50px",
                    slidesToShow: 3,
                }
            }, {
                breakpoint: 576,
                settings: {
                    centerMode: false,
                    slidesToShow: 2,
                    centerPadding: "40px",
                }
            }, {
                breakpoint: 380,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    centerPadding: "40px",
                }
            }
        ]
    };

    return (
        <>
            <div className="slider-container">
                <Slider {...settings}>
                    <div className="item px-3">
                        <img src={cat_image_1} alt="image" className="img-fluid" />
                        <div className="category_info">
                            <h3>Backpacks</h3>
                            <p>16 products</p>
                            <p className="category_link">
                                <a href="#">Shop now <i className="fa-solid fa-arrow-right ml-1"></i></a>
                            </p>
                        </div>
                    </div>
                    <div className="item px-3">
                        <img src={cat_image_2} alt="image" className="img-fluid" />
                        <div className="category_info">
                            <h3>Clutches</h3>
                            <p>16 products</p>
                            <p className="category_link">
                                <a href="#">Shop now <i className="fa-solid fa-arrow-right ml-1"></i></a>
                            </p>
                        </div>
                    </div>
                    <div className="item px-3">
                        <img src={cat_image_3} alt="image" className="img-fluid" />
                        <div className="category_info">
                            <h3>Handbags</h3>
                            <p>16 products</p>
                            <p className="category_link">
                                <a href="#">Shop now <i className="fa-solid fa-arrow-right ml-1"></i></a>
                            </p>
                        </div>
                    </div>
                    <div className="item px-3">
                        <img src={cat_image_4} alt="image" className="img-fluid" />
                        <div className="category_info">
                            <h3>Wallets</h3>
                            <p>16 products</p>
                            <p className="category_link">
                                <a href="#">Shop now <i className="fa-solid fa-arrow-right ml-1"></i></a>
                            </p>
                        </div>
                    </div>
                </Slider>
            </div>
        </>
    );
}

export default CategorySlider;
