import React from 'react'
import { Link } from 'react-router-dom'
import { useProductFilter } from '../ContextAPI/Components/productFilter'
import { File_URL } from "../config";
import { handleImageError } from "../helpers/helper";
import { FaRegHeart } from 'react-icons/fa'
import { IoCartOutline } from 'react-icons/io5'


function ProductCard() {

    const { products } = useProductFilter()

    return (
        <>

            {products.map((product, i) => {
                return <>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <Link to={`/single-product/${product.id}`}>
                            <div className="f_p_item">
                                <div className="f_p_img">
                                    <img className="img-fluid" src={`${File_URL}/${product.image_url}`} alt="image" onError={handleImageError} />
                                    {/* <img className="img-fluid" src={product.img[0]} alt="image" /> */}
                                    <div className="curve_box">
                                        <div className="p_icon">
                                            {/* <a href=''> <FaRegHeart className='p-icon' /> </a> */}
                                            <a href=''> <IoCartOutline className='p-icon' /> </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="f_p_body">
                                    <h4 className='p_name'>{product.name}</h4>
                                    {/* <div className="d-flex jc-center ai-center mb-2">
                                        <div className="small-ratings me-2">
                                            <i className="fa fa-star rating-color"></i>
                                            <i className="fa fa-star rating-color"></i>
                                            <i className="fa fa-star rating-color"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <h4 className="review">1 review</h4>
                                    </div> */}
                                    <h5>${product.price}</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                </>
            })}

        </>
    )
}

export default ProductCard
