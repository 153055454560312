import './App.css';
import './css/bootstrap.css';
import './css/style.css';
import './css/font-awesome.min.css';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Route, Routes } from 'react-router-dom';
import {useAuth} from './ContextAPI/Components/auth';
import Home from './pages/Home';
import Shop from './pages/Shop';
import SingleProduct from './pages/SingleProduct';
import Wishlist from './pages/Wishlist';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Tracking from './pages/Tracking';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import axios from 'axios';

import Dashboard from './pages/UserDashboard/Dashboard';
import Profile from './pages/UserDashboard/Profile';
import Addresses from './pages/UserDashboard/Addresses';
import MyOrders from './pages/UserDashboard/MyOrders';
import Password from './pages/UserDashboard/Password';

import AdminDashboard from './AdminPages/AdminDashboard';
import AddProducts from './AdminPages/products/AddProducts';
import EditProduct from './AdminPages/products/EditProduct';
import AddCategory from './AdminPages/AddCategory';
import AddVariants from './AdminPages/AddVariants';
import AddImages from './AdminPages/AddImages';
import AddProductVariants from './AdminPages/AddProductVariants';

// Admin Panel
import A_Products from './AdminPages/products/getProducts';
import ThankYouPage from './pages/ThankYouPage';
import UI_Kit from './AdminPages/UI_Kit';
import Page_404 from './pages/Page_404';


function App() {
  const {GetLoginUSer, user} = useAuth()
  const [cookies, setCookie, removeCookie] = useCookies(['pk2']);

  console.log("cookie", cookies);
  
  axios.defaults.headers["authorization"] = `Bearer ${cookies.pk2 || null}`
  axios.defaults.headers["ngrok-skip-browser-warning"] = `1211`


  useEffect(() => {
    if(user == null && cookies.pk2 != undefined ) GetLoginUSer()

  }, [user])



  return (
    <>

      {/* <Navbar /> */}
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/shop' element={<Shop />} />
        <Route exact path='/single-product/:id' element={<SingleProduct />} />
        <Route exact path='/wishlist' element={<Wishlist />} />
        <Route exact path='/cart' element={<Cart />} />
        <Route exact path='/checkout' element={<Checkout />} />
        <Route exact path='/tracking/:id' element={<Tracking />} />
        <Route exact path='/sign-in' element={<Signin />} />
        <Route exact path='/sign-up' element={<Signup />} />

        {/* Admin Panel */}
        <Route exact path='/admin/dashboard' element={<AdminDashboard />} />
        {/* for adding product  */}
        <Route exact path='/admin/add-product' element={<AddProducts />} />
        <Route exact path='/admin/edit-product/:id' element={<EditProduct />} />
        <Route exact path='/admin/add-product-category' element={<AddCategory />} />
        <Route exact path='/admin/add-product-variant/:id/:category' element={<AddProductVariants />} />
        <Route exact path='/admin/add-images/:id' element={<AddImages />} />
        <Route exact path='/admin/add-variant' element={<AddVariants />} />
        <Route exact path='/admin/products' element={<A_Products />} />


        
        <Route exact path='/admin/ui-kit' element={<UI_Kit />} />


        {/* user deshboard */}

        <Route exact path='/dashboard' element={<Dashboard />} />
        <Route exact path='/profile' element={<Profile />} />
        <Route exact path='/addresses' element={<Addresses />} />
        <Route exact path='/my-orders' element={<MyOrders />} />
        <Route exact path='/password' element={<Password />} />


        {/* Admin Panel */}
        <Route exact path='/page-404' element={<Page_404 />} />
        <Route exact path='/thankyou' element={<ThankYouPage />} />

        

      </Routes>
      {/* <Footer /> */}
    </>
  );
}

export default App;
